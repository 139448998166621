import { useMutation, useQueryClient } from '@tanstack/react-query';
import { submissions } from 'services/api';
import { CreateNilReturnArgs } from 'services/api/submissions/types';

export default function useCreateNilReturn({
    instId,
    collectionId,
}: CreateNilReturnArgs) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () =>
            submissions.createNilReturn({
                instId,
                collectionId,
            }),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['submissions', 'provider-statuses'],
            });
        },
    });
}
