import { useQuery } from '@tanstack/react-query';
import { GetReportDataXlsxArgs } from 'services/api/credibility/types';

import { getReportDownloadQueryOptions } from './options';

export default function useCredibilityReportDownload({
    reportCode,
    submissionId,
}: GetReportDataXlsxArgs) {
    const options = getReportDownloadQueryOptions({ reportCode, submissionId });
    return useQuery({
        ...options,
        select: data => ({
            href: data.contents,
            download: data.fileName,
        }),
    });
}
