import { RouteObject } from 'react-router-dom';
import AuthRoute from 'components/AuthRoute/AuthRoute';

const irisRoutes: RouteObject[] = [
    {
        path: 'iris-reprocessing',
        element: (
            <AuthRoute
                allowHesa={[
                    'non-pii-edit',
                    'pii-edit',
                    'special-category-edit',
                    'admin',
                ]}
                allowStatutoryCustomers="analyst"
            />
        ),
        handle: {
            crumb: 'IRIS',
        },
        children: [
            {
                index: true,
                async lazy() {
                    const IrisReprocessing = await import(
                        'pages/IrisReprocessing/IrisReprocessing'
                    );
                    return { Component: IrisReprocessing.default };
                },
            },
        ],
    },
];

export default irisRoutes;
