import { useMutation, useQueryClient } from '@tanstack/react-query';
import { submissions } from 'services/api';

export default function useSignOffSubmission({
    submissionId,
}: {
    submissionId: number;
}) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () => submissions.signoff({ submissionId }),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['submissions', 'provider-statuses'],
            });
        },
    });
}
