import { useCallback } from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import { ValidationInterface } from 'queries/specifications/types';
import { getRuleMetadata } from 'queries/specifications/utils';

import useEditModal from '../EditModal/useEditModal';
import MoveSpecificationToDraft from '../MoveSpecificationToDraft/MoveSpecificationToDraft';

interface RuleMetadataProps {
    validation: ValidationInterface;
}

export default function RuleMetadata({ validation }: RuleMetadataProps) {
    const { description, guidanceUrl } = getRuleMetadata(validation);
    const validationId = validation.id;

    const { editRuleMetadata, spec } = useEditModal();

    const handleEditRuleMetadata = useCallback(
        () => editRuleMetadata(validationId),
        [editRuleMetadata, validationId],
    );

    return (
        <>
            <Box p={3} pt={0}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant={'subtitle1'}>
                            Rule description
                        </Typography>
                        <Typography variant={'body2'}>{description}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant={'subtitle1'}>
                            Guidance link
                        </Typography>
                        <Link
                            href={guidanceUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Typography
                                variant={'body2'}
                                style={{ wordWrap: 'break-word' }}
                            >
                                {guidanceUrl}
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
            <MoveSpecificationToDraft
                spec={spec}
                message={'Edit rule metadata?'}
            >
                <Link
                    component={'button'}
                    variant={'body2'}
                    onClick={handleEditRuleMetadata}
                >
                    Edit rule metadata
                </Link>
            </MoveSpecificationToDraft>
        </>
    );
}
