import { post } from 'services/api/utils';
import {
    SchemaCheckFailure,
    Submission,
    SubmissionFailureSummary,
    SubmissionStateHistoryEntry,
    SubmissionStatistics,
} from 'src/types/submission';

import { get, getRaw } from '../utils';

import {
    CreateIssueArgs,
    CreateNilReturnArgs,
    GetCompareSubmissionsValidationsArgs,
    GetCompareSubmissionsValidationsResponse,
    GetLatestSubmissionArgs,
    GetLatestSubmissionResponse,
    GetProviderStatusesArgs,
    GetProviderStatusesResponse,
    GetSchemaChecksArgs,
    GetStatesResponse,
    GetSubmissionCommitAllowedStatusArgs,
    GetSubmissionCommitAllowedStatusResponse,
    GetSubmissionsArgs,
    GetSubmissionsResponse,
    GetSubmissionValidationDetailsArgs,
    GetSubmissionValidationsArgs,
    GetSubmissionValidationsResponse,
    RequestCredibilityArgs,
    UpdateSubmissionStateArgs,
    UpdateToResubmissionRequiredArgs,
} from './types';

export const getProviderStatuses = ({
    collectionReference,
    instId,
    ovt,
}: GetProviderStatusesArgs) => {
    const endpoint = ovt
        ? '/submissions/ovt/provider-statuses'
        : '/submissions/provider-statuses';

    return get(endpoint, {
        query: { collectionReference, instId },
    }) as Promise<GetProviderStatusesResponse>;
};

export const getOvtProviderStatuses = ({
    collectionReference,
    instId,
}: GetProviderStatusesArgs) =>
    get(`/submissions/ovt/provider-statuses`, {
        query: { collectionReference, instId },
    }) as Promise<GetProviderStatusesResponse>;

export const getSchemaChecks = ({
    submissionId,
    instId,
    offset,
    limit,
    ovt,
}: GetSchemaChecksArgs) => {
    const endpoint = ovt
        ? `/submissions/ovt/${submissionId}/schemachecks`
        : `/submissions/${submissionId}/schemachecks`;

    return get(endpoint, {
        query: { instId, offset, limit },
    }) as Promise<SchemaCheckFailure[]>;
};

export const getValidations = ({
    submissionId,
    instId,
    status,
    searchTerm,
    sortBy,
    sortOrder,
    offset,
    limit,
    ovt = false,
}: GetSubmissionValidationsArgs) => {
    const endpoint = ovt
        ? `/submissions/ovt/${submissionId}/validations`
        : `/submissions/${submissionId}/validations`;

    return get(endpoint, {
        query: {
            instId,
            status,
            sortBy,
            sortOrder,
            searchTerm,
            limit,
            ...(offset && { offset: offset * limit }),
        },
    }) as Promise<GetSubmissionValidationsResponse>;
};

export const getCompareValidations = ({
    submissionId,
    previousSubmissionId,
    instId,
    status,
    searchTerm,
    sortBy,
    sortOrder,
    offset,
    limit,
    hideUnchangedValues,
}: GetCompareSubmissionsValidationsArgs) => {
    return get(
        `/submissions/${submissionId}/validations/compare/${previousSubmissionId}`,
        {
            query: {
                instId,
                status,
                sortBy,
                sortOrder,
                searchTerm,
                limit,
                hideUnchangedValues,
                ...(offset && { offset: offset * limit }),
            },
        },
    ) as Promise<GetCompareSubmissionsValidationsResponse>;
};

export const getValidationsCsv = ({
    submissionId,
    instId,
    status,
    searchTerm,
    sortBy,
    sortOrder,
    offset,
    limit,
    ovt = false,
}: Omit<GetSubmissionValidationsArgs, 'csv'>) => {
    const endpoint = ovt
        ? `/submissions/ovt/${submissionId}/validations`
        : `/submissions/${submissionId}/validations`;

    return getRaw(endpoint, {
        query: {
            instId,
            status,
            sortBy,
            sortOrder,
            searchTerm,
            limit,
            ...(offset && { offset: offset * limit }),
        },
        headers: {
            Accept: 'text/csv',
        },
    }).then(res => res.text());
};

export const getValidationDetails = ({
    submissionId,
    validationId,
    instId,
    regulatorCode,
    offset,
    limit,
    contentType = 'application/json',
    ovt = false,
}: GetSubmissionValidationDetailsArgs) => {
    const endpoint = ovt
        ? `/submissions/ovt/${submissionId}/validations/${validationId}`
        : `/submissions/${submissionId}/validations/${validationId}`;

    if (contentType === 'application/json') {
        return get(endpoint, {
            query: {
                instId,
                limit,
                regulator: regulatorCode,
                ...(offset && { offset: offset * limit }),
            },
            headers: { Accept: contentType },
        });
    } else {
        return getRaw(endpoint, {
            query: {
                instId,
                limit,
                regulator: regulatorCode,
                ...(offset && { offset: offset * limit }),
            },
            headers: { Accept: contentType },
        });
    }
};

export const getValidationDetailsCsv = (
    args: Omit<GetSubmissionValidationDetailsArgs, 'contentType'>,
) =>
    getValidationDetails({ ...args, contentType: 'text/csv' }).then(res =>
        res.text(),
    );

export const getValidationDetailsUri = (
    args: Omit<GetSubmissionValidationDetailsArgs, 'contentType'>,
) =>
    getValidationDetails({ ...args, contentType: 'text/uri-list' }).then(res =>
        res.text(),
    ) as Promise<string>;

export const getStates = async () =>
    get('/submissions/states') as Promise<GetStatesResponse>;

export const getSubmissionHistory = async ({
    submissionId,
}: {
    submissionId: string;
}) =>
    get(`/submissions/${submissionId}/submission-history`) as Promise<
        SubmissionStateHistoryEntry[]
    >;

export const getSubmission = async ({
    submissionId,
    isOvt = false,
}: {
    submissionId: string;
    isOvt: boolean;
}) => {
    const endpoint = isOvt
        ? `/submissions/ovt/${submissionId}`
        : `/submissions/${submissionId}`;

    return get(endpoint) as Promise<Submission>;
};

export const getLatestSubmission = async ({
    collectionId,
    instId,
}: GetLatestSubmissionArgs) => {
    return get(
        `/submissions/latest/${collectionId}/${instId}`,
    ) as Promise<GetLatestSubmissionResponse>;
};

export const getSubmissionCommitAllowedStatus = async ({
    submissionId,
}: GetSubmissionCommitAllowedStatusArgs) => {
    return get(
        `/submissions/${submissionId}/commit-allowed-status`,
    ) as Promise<GetSubmissionCommitAllowedStatusResponse>;
};

export const getSubmissions = async ({
    collectionId,
    instId,
    isOvt = false,
}: GetSubmissionsArgs) => {
    const endpoint = isOvt ? '/submissions/ovt' : '/submissions';

    return get(endpoint, {
        query: { collectionId, instId },
    }) as Promise<GetSubmissionsResponse>;
};
export const createNilReturn = async ({
    instId,
    collectionId,
}: CreateNilReturnArgs) =>
    post(`/submissions/create-nil-submission`, {
        body: { collectionId: String(collectionId), instId: String(instId) },
        headers: {},
    });

export const updateSubmissionState = async ({
    action,
    statusId,
    submissionId,
}: UpdateSubmissionStateArgs) =>
    post(`/submissions/update-state/${submissionId}`, {
        body: { action, statusId },
        headers: {},
    });

export const updateToResubmissionRequired = async ({
    instIds,
    collectionReference,
}: UpdateToResubmissionRequiredArgs) =>
    post('/submissions/update-to-resubmission-required-historic-amendment', {
        body: { instIds, collectionReference },
        headers: {},
    });

export const signoff = async ({ submissionId }: { submissionId: number }) =>
    post(`/submissions/${submissionId}/signoff`, { body: {}, headers: {} });

export const getFailureSummaryTable = ({
    submissionId,
    instId,
}: {
    submissionId: string;
    instId: string;
}) =>
    get(`/submissions/${submissionId}/failure-summary-table`, {
        query: { instId },
    }) as Promise<SubmissionFailureSummary>;

export const getFileStatistics = ({
    submissionId,
    instId,
}: {
    submissionId: string;
    instId: string;
}) =>
    get(`/submissions/${submissionId}/statistics`, {
        query: { instId },
    }) as Promise<SubmissionStatistics[]>;

export const createIssue = async ({
    validationCode,
    regulatorCode,
    submissionUuid,
}: CreateIssueArgs) =>
    post(`/submissions/${submissionUuid}/issues`, {
        body: { validationCode, regulatorCode },
        headers: {},
    });

export const getOvtFailureCounts = ({
    submissionId,
    instId,
}: {
    submissionId: string;
    instId: string;
}) =>
    get(`/submissions/ovt/${submissionId}/failurecounts`, {
        query: { instId },
    });

export const requestCredibility = async ({
    submissionUuid,
    instId,
    collectionId,
    fileName,
    providerName,
}: RequestCredibilityArgs) =>
    post(`/submissions/requestCredibility`, {
        body: { submissionUuid, instId, collectionId, fileName, providerName },
        headers: {},
    });

export const requestResubmission = async ({ uuid }: { uuid: string }) =>
    post(`/submissions/${uuid}/request-resubmission/`);
