import { useMutation, useQueryClient } from '@tanstack/react-query';
import { submissions } from 'services/api';
import { LatestSubmission } from 'types/submission';

interface UseApproveSubmissionArgs {
    latestSubmission: LatestSubmission;
}
export default function useApproveSubmission({
    latestSubmission,
}: UseApproveSubmissionArgs) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () =>
            submissions.updateSubmissionState({
                action: 'approve',
                statusId: latestSubmission?.nextApprovalState!.id,
                submissionId: latestSubmission?.uuid,
            }),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['submissions', 'provider-statuses'],
            });
        },
    });
}
