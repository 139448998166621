import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { ReactComponent as DownArrow } from 'assets/icons/down_arrow.svg';
import { ReactComponent as DrillDownCorner } from 'assets/icons/drill_down_corner.svg';
import { ReactComponent as Exclamation } from 'assets/icons/error.svg';
import { ReactComponent as UpArrow } from 'assets/icons/up_arrow.svg';
import classNames from 'classnames';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { CredibilityReportCellData } from 'types/credibility';

import styles from './credibilityCell.module.scss';

interface CellInteriorProps {
    className: string;
    cellId: string;
    canDrillDown: boolean;
    cellData: CredibilityReportCellData;
    isExpanded: boolean;
    clickHandler: (cellId: string) => void;
}

export default function CellInterior({
    className,
    cellId,
    canDrillDown,
    cellData,
    isExpanded,
    clickHandler,
}: CellInteriorProps) {
    const {
        ShadingRules,
        IconUpArrow,
        IconDownArrow,
        IconExclamation,
        ChangeTooltipText,
        TopLeftLabel,
        TopLeftValue,
        TopLeftSuffix,
        TopRightLabel,
        TopRightValue,
        TopRightSuffix,
        BottomLeftLabel,
        BottomLeftValue,
        BottomLeftSuffix,
        BottomRightLabel,
        BottomRightValue,
        BottomRightSuffix,
    } = cellData;

    const dynamicCell = classNames({
        [styles.innerCell]: true,
        [styles.hidden]: !isExpanded,
    });

    const leftColumn = classNames({
        [styles.column]: true,
        [styles.leftColumn]: true,
    });

    const rightColumn = classNames({
        [styles.column]: true,
        [styles.rightColumn]: true,
        [styles.hidden]: !isExpanded,
    });

    return (
        <td className={className}>
            {ShadingRules && ShadingRules?.RuleText?.length ? (
                <div className={styles.infoWrapper}>
                    <Tooltip
                        id="cell-info-tooltip"
                        leaveDelay={200}
                        arrow
                        classes={{ popper: 'hdp-override' }}
                        title={
                            <div style={{ whiteSpace: 'pre-line' }}>
                                {ShadingRules.RuleText.join('\n')}
                            </div>
                        }
                    >
                        <InfoOutlined
                            className={styles.info}
                            aria-label="rule information icon"
                        />
                    </Tooltip>
                </div>
            ) : null}
            <div className={styles.credibilityCell}>
                <div className={leftColumn}>
                    <div className={styles.innerCell}>
                        <span className={styles.label}>{TopLeftLabel}</span>
                        <span className={styles.dataIcons}>
                            {IconDownArrow && ChangeTooltipText && (
                                <Tooltip
                                    id="cell-value-decrease-tooltip"
                                    leaveDelay={200}
                                    arrow
                                    classes={{ popper: 'hdp-override' }}
                                    title={ChangeTooltipText}
                                >
                                    <DownArrow aria-label="value decrease icon" />
                                </Tooltip>
                            )}
                            {IconUpArrow && ChangeTooltipText && (
                                <Tooltip
                                    id="cell-value-increase-tooltip"
                                    leaveDelay={200}
                                    arrow
                                    classes={{ popper: 'hdp-override' }}
                                    title={ChangeTooltipText}
                                >
                                    <UpArrow aria-label="value increase icon" />
                                </Tooltip>
                            )}
                            <span className={styles.dataIconsLabel}>
                                {TopLeftValue}
                                {TopLeftSuffix}
                            </span>
                            {IconExclamation && (
                                <Exclamation
                                    className={styles.noPointer}
                                    aria-label="new failure icon"
                                />
                            )}
                        </span>
                    </div>
                    <div className={dynamicCell} aria-hidden={!isExpanded}>
                        <span className={styles.label}>{BottomLeftLabel}</span>
                        <span className={styles.data}>
                            {BottomLeftValue}
                            {BottomLeftSuffix}
                        </span>
                    </div>
                </div>
                <div className={rightColumn} aria-hidden={!isExpanded}>
                    <div className={dynamicCell}>
                        <span className={styles.label}>{TopRightLabel}</span>
                        <span className={styles.data}>
                            {TopRightValue}
                            {TopRightSuffix}
                        </span>
                    </div>
                    <div className={dynamicCell} aria-hidden={!isExpanded}>
                        <span className={styles.label}>{BottomRightLabel}</span>
                        <span className={styles.data}>
                            {BottomRightValue}
                            {BottomRightSuffix}
                        </span>
                    </div>
                </div>
            </div>
            {canDrillDown && parseFloat(TopLeftValue.toString()) ? (
                <DrillDownCorner
                    className={styles.drillDownCorner}
                    onClick={() => clickHandler(cellId)}
                    aria-label="open drilldown details"
                    role="button"
                    aria-haspopup="dialog"
                    data-test-id={formatTestId('drill down', cellId)}
                />
            ) : null}
        </td>
    );
}
