import { queryOptions } from '@tanstack/react-query';
import { GetActivityLogRequestArgs } from 'services/api/collections/types';
import {
    getActivityLog,
    getAllCollections,
} from 'src/services/api/collections/collections';

import { CollectionsQueryOptions } from './types';

export function getUseCollectionsQueryOptions({
    limit,
    offset,
}: CollectionsQueryOptions) {
    return queryOptions({
        queryKey: ['collections', limit, offset],
        queryFn: () => getAllCollections({ limit, offset }),
    });
}

export function getUseSingleCollectionQueryOptions() {
    return queryOptions({
        queryKey: ['collections'],
        queryFn: () => getAllCollections(),
    });
}

export function getUseCollectionActivityLogOptions({
    collectionReference,
    limit,
    offset,
    instId,
}: GetActivityLogRequestArgs) {
    return queryOptions({
        queryKey: [
            'collections',
            'activity-log',
            collectionReference,
            limit,
            offset,
            instId,
        ],
        queryFn: () =>
            getActivityLog({ collectionReference, limit, offset, instId }),
    });
}
