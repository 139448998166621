import { MenuItem } from '@mui/material';
import { RestrictAccess } from 'components';

interface AwaitingActionMenuItemProps {
    label: string;
    permissions: string[];
    onClick: () => void;
    dataTestId: string;
}

export default function AwaitingActionMenuItem({
    label,
    permissions,
    onClick,
    dataTestId,
}: AwaitingActionMenuItemProps) {
    return (
        <RestrictAccess allowPermissions={permissions}>
            <MenuItem onClick={() => onClick()} data-test-id={dataTestId}>
                {label}
            </MenuItem>
        </RestrictAccess>
    );
}
