import { Clear } from '@mui/icons-material';
import { IconButton } from '@mui/material';

interface CancelButtonProps {
    title?: string;
    disabled?: boolean;
    onClick: () => void;
    dataTestId?: string;
}

export default function CancelButton({
    onClick,
    disabled = false,
    title,
    dataTestId,
}: CancelButtonProps) {
    return (
        <IconButton
            onClick={onClick}
            disabled={disabled}
            size="large"
            title={title}
            data-test-id={dataTestId}
        >
            <Clear />
        </IconButton>
    );
}
