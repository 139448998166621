import { useMemo } from 'react';
import { useUserContext } from 'components';
import TabPanel from 'components/Tabs/TabPanel';
import { DateTime } from 'luxon';
import useProviderStatuses from 'queries/submissions/useProviderStatuses';
import useSubmissionStates from 'queries/submissions/useSubmissionStates';
import Loading from 'src/components/Loading/Loading';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import useTableParams from 'src/hooks/useTableParams/useTableParams';
import { ProviderStatus } from 'types/provider';

import Filters from '../SubmissionSummary/Filters/Filters';
import {
    filterSubmissions,
    SubmissionHydrated,
} from '../SubmissionSummary/utils';
import {
    addAwaitingParty,
    addTotalSteps,
    getStateOfQueries,
    pipe,
    queryStates,
    transformToAwaitingParties,
    transformToSteps,
} from '../utils';

import { OvtSubmissionSummaryTable } from './OvtSubmissionSummaryTable/OvtSubmissionSummaryTable';

const sortByLastSubmission = (a: ProviderStatus, b: ProviderStatus) => {
    const aDate = DateTime.fromISO(a.latestSubmission.uploaded);
    const bDate = DateTime.fromISO(b.latestSubmission.uploaded);

    if (aDate < bDate) return 1;
    else if (aDate === bDate) return 0;
    else return -1;
};

const sortByStep =
    (prop: 'latestSubmission' | 'furthestSubmission') =>
    (order: string) =>
    (a: ProviderStatus, b: ProviderStatus) => {
        if (a[prop] && b[prop]) {
            if (a[prop].status.step.number < b[prop].status.step.number)
                return order === 'desc' ? 1 : -1;

            if (a[prop].status.step.number > b[prop].status.step.number)
                return order === 'desc' ? -1 : 1;
        }

        if (b[prop] && !a[prop]) return order === 'desc' ? 1 : -1;
        if (!b[prop] && a[prop]) return order === 'desc' ? -1 : 1;

        if (a.provider.name > b.provider.name) return 1;
        if (a.provider.name < b.provider.name) return -1;

        return 0;
    };

const sortByCurrentStep = sortByStep('latestSubmission');
const sortByFurthestStep = sortByStep('furthestSubmission');

const sortOvtResults = (
    array: ProviderStatus[],
    order: string,
    orderBy: string,
) => {
    let sortedArray;

    switch (orderBy) {
        case 'last-submission':
            sortedArray = array
                .filter(sub => !!sub?.latestSubmission?.uploaded)
                .sort(sortByLastSubmission);
            if (order !== 'desc') sortedArray.reverse();

            return sortedArray.concat(
                array.filter(sub => !sub?.latestSubmission?.uploaded),
            );

        case 'current-step':
            return array.toSorted(sortByCurrentStep(order));

        case 'furthest-step':
            return array.toSorted(sortByFurthestStep(order));

        default:
            return array;
    }
};

const useOvtProviderSubmissions = ({
    collectionReference,
    sortBy,
    sortOrder,
}: {
    collectionReference: string;
    isHesa: boolean;
    sortBy: string;
    sortOrder: string;
}) => {
    const ovtProviderStatuses = useProviderStatuses({
        collectionReference,
        ovt: true,
    });

    const submissionStates = useSubmissionStates();

    const ovtStatus = useMemo(
        () => getStateOfQueries([ovtProviderStatuses, submissionStates]),
        [ovtProviderStatuses, submissionStates],
    );

    const ovtSubmissions = useMemo(() => {
        if (!submissionStates.data || ovtStatus !== queryStates.LOADED)
            return [];

        return ovtProviderStatuses.data?.map(
            pipe(
                addTotalSteps(transformToSteps(submissionStates.data)),
                addAwaitingParty(
                    transformToAwaitingParties(submissionStates.data),
                ),
            ),
        );
    }, [ovtProviderStatuses, submissionStates, ovtStatus]);

    const sortedOvtSubmissions = sortOvtResults(
        ovtSubmissions || [],
        sortOrder,
        sortBy,
    );

    return {
        ovtStatus,
        ovtSubmissions: sortedOvtSubmissions,
    };
};

interface OvtSubmissionSummaryTableParams {
    offset: number;
    limit: number;
    selectedProviders: string[];
    selectedStatuses: string[];
    selectedRegulators: string[];
    sortBy: string;
    sortOrder: string;
}

export const OvtSubmissionSummary = ({
    collectionReference,
}: {
    collectionReference: string;
}) => {
    const { isHesa } = useUserContext();

    const DEFAULT_TABLE_PARAMETERS = {
        offset: 0,
        limit: 10,
        selectedProvider: {},
        selectedProviders: [],
        selectedStatuses: [],
        selectedRegulators: [],
        sortBy: 'last-submission',
        sortOrder: 'desc',
    };

    const tableId = TABLE_PARAMS_IDS.SUBMISSIONS_SUMMARY_OVT;

    const { values: tableParams, methods: tableParamsMethods } =
        useTableParams<OvtSubmissionSummaryTableParams>(
            tableId,
            DEFAULT_TABLE_PARAMETERS,
        );

    const {
        limit,
        offset,
        sortBy,
        sortOrder,
        selectedProviders,
        selectedStatuses,
        selectedRegulators,
    } = tableParams;

    const {
        setLimit,
        setOffset,
        setSortBy,
        setSortOrder,
        setSelectedProviders,
        setSelectedRegulators,
        setSelectedStatuses,
    } = tableParamsMethods;

    const { ovtStatus, ovtSubmissions } = useOvtProviderSubmissions({
        collectionReference,
        isHesa,
        sortBy,
        sortOrder,
    });

    const handleSortChange = (id: string) => {
        if (sortBy === id) {
            // If already sorting by the same column, flip the order
            const flippedSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
            setSortOrder(flippedSortOrder);
        } else {
            // If sorting by a different column, set the order to the default
            setSortOrder(DEFAULT_TABLE_PARAMETERS.sortOrder);
            setSortBy(id);
        }
    };

    const submissionsWithStates =
        (ovtSubmissions?.map(submission => ({
            ...submission,
            state: submission?.latestSubmission?.status?.id || 0,
        })) as SubmissionHydrated[]) || [];

    const filteredSubmissions = filterSubmissions(
        submissionsWithStates,
        selectedProviders,
        selectedStatuses,
        selectedRegulators,
    );

    const providers = ovtSubmissions.map(s => s.provider);

    const renderContent = () => {
        switch (ovtStatus) {
            case queryStates.LOADING:
                return <Loading />;

            case queryStates.LOADED:
                return (
                    <>
                        <TabPanel value={0} index={0}>
                            <Filters
                                isOvt
                                providers={providers}
                                submissions={submissionsWithStates}
                                setSelectedProviders={setSelectedProviders}
                                setSelectedRegulators={setSelectedRegulators}
                                setSelectedStatuses={setSelectedStatuses}
                                selectedProviders={selectedProviders}
                                selectedRegulators={selectedRegulators}
                                selectedStatuses={selectedStatuses}
                            />
                            <OvtSubmissionSummaryTable
                                submissions={filteredSubmissions}
                                order={sortOrder as 'asc' | 'desc'}
                                orderBy={sortBy}
                                onSort={handleSortChange}
                                limit={limit}
                                setLimit={setLimit}
                                offset={offset}
                                setOffset={setOffset}
                            />
                        </TabPanel>
                    </>
                );

            default:
                return null;
        }
    };

    return <>{renderContent()}</>;
};
