import React, { SyntheticEvent, useEffect, useState } from 'react';
import { AutocompleteChangeReason } from '@mui/material';
import AutocompleteDropdown from 'components/AutocompleteDropdown/AutocompleteDropdown';
import { AutocompleteOption } from 'components/AutocompleteDropdown/AutocompleteDropdown';
import { getAll } from 'services/api/regulators';

import { SubmissionHydrated } from '../utils';

import styles from './filters.module.scss';

interface Regulator {
    name: string;
    regulatorCode: string;
    regulatorTypeCode: string;
}

interface RegulatorFilterProps {
    onChange: (
        event: SyntheticEvent,
        value: string[],
        reason: AutocompleteChangeReason,
    ) => void;
    submissions: SubmissionHydrated[];
    value: string[];
}

const RegulatorFilter = ({
    onChange,
    submissions,
    value,
}: RegulatorFilterProps) => {
    const [regulators, setRegulators] = useState<Regulator[]>([]);

    const getPrimaryRegulators = async () => {
        const { regulators } = await getAll();
        setRegulators(
            regulators.filter(
                (regulator: Regulator) => regulator.regulatorTypeCode === '01',
            ),
        );
    };

    const sortAlphabetically = (a: Regulator, b: Regulator) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
    };

    useEffect(() => {
        getPrimaryRegulators();
    }, []);

    const regulatorOptions: AutocompleteOption[] = regulators
        .sort(sortAlphabetically)
        .map(regulator => ({
            name: regulator.name,
            code: regulator.regulatorCode,
            count:
                submissions?.filter(
                    submission =>
                        submission.provider.regulatorCode ===
                        regulator.regulatorCode,
                ).length ?? 0,
        }))
        .map(regulator => ({
            label: `${regulator.name} (${regulator.count})`,
            value: regulator.code,
        }));

    const handleChange = (
        event: SyntheticEvent,
        newValue: string[],
        reason: AutocompleteChangeReason,
    ) => {
        onChange(event, newValue, reason);
    };

    return (
        <div className={styles.filterContainer}>
            <AutocompleteDropdown
                id="regulators-filter"
                placeholder="Filter by regulator"
                value={value}
                options={regulatorOptions}
                onChange={handleChange}
                label="Filter by Regulator"
            />
        </div>
    );
};

RegulatorFilter.displayName = 'RegulatorFilter';

export default RegulatorFilter;
