import { useMutation, useQueryClient } from '@tanstack/react-query';
import { submissions } from 'services/api';

export default function useRequestResubmission({ uuid }: { uuid: string }) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () => submissions.requestResubmission({ uuid }),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['submissions', 'provider-statuses'],
            });
        },
    });
}
