import React from 'react';
import { SpecificationInterface } from 'queries/specifications/types';

interface EditModalContextValue {
    editRuleMetadata: (id: number) => void;
    editTolerances: (id: number) => void;
    spec: SpecificationInterface;
}

export const EditModalContext =
    React.createContext<EditModalContextValue | null>(null);
