import { Box, Button, Typography } from '@mui/material';
import StepSummaryBox from 'components/StepSummaryBox/StepSummaryBox';
import useProviderStatuses from 'queries/submissions/useProviderStatuses';
import useSubmissionStates from 'queries/submissions/useSubmissionStates';
import Loading from 'src/components/Loading/Loading';
import { ProviderStatus } from 'types/provider';
import { SubmissionStatus } from 'types/submission';

import { GroupedStep } from './types';

import styles from './stepSummary.module.scss';

const stepNumberForNotStarted = 0;

const groupStepsByStepId = (steps: SubmissionStatus[]): GroupedStep => {
    // Group steps by step id
    if (!steps) {
        return {};
    }
    const groupedSteps: GroupedStep = {};
    for (let i = 0; i < steps.length; i++) {
        const step = { ...steps[i], count: 0 };
        let stepId = step.step.id;
        if (stepId === null) {
            stepId = 0;
        }
        if (!groupedSteps[stepId]) {
            groupedSteps[stepId] = [step];
        } else {
            groupedSteps[stepId].push(step);
        }
    }
    return groupedSteps;
};

const mergeAndRenameStepsForDisplay = (steps: GroupedStep): GroupedStep => {
    const mergedSteps = { ...steps };

    // Merge Awaiting Sign off and Signed off steps
    const awaitingSignOffIndex = Object.values(mergedSteps).findIndex(
        step => step[0].step.name === 'Awaiting sign off',
    );

    const signedOffIndex = Object.values(mergedSteps).findIndex(
        step => step[0].step.name === 'Signed off',
    );

    const awaitingSignOff = mergedSteps[awaitingSignOffIndex];
    const signedOff = mergedSteps[signedOffIndex];
    mergedSteps[signedOffIndex] = [...signedOff, ...awaitingSignOff];

    // Merge schema check, Right to Process Check, and enrichment steps into one step (under Enrichment)
    const schemaCheckIndex = Object.values(mergedSteps).findIndex(
        step => step[0].step.name === 'Schema check',
    );
    const enrichmentIndex = Object.values(mergedSteps).findIndex(
        step => step[0].step.name === 'Enrichment',
    );
    const rightToProcessCheckIndex = Object.values(mergedSteps).findIndex(
        step => step[0].step.name === 'Right to process check',
    );
    const rightToProcessCheck = mergedSteps[rightToProcessCheckIndex];

    const schemaCheck = mergedSteps[schemaCheckIndex];
    const enrichment = mergedSteps[enrichmentIndex];
    mergedSteps[schemaCheckIndex] = [
        ...schemaCheck,
        ...enrichment,
        ...rightToProcessCheck,
    ];

    /*
     * Delete the steps that were merged away.
     * Note: This needs to be done with the indexes in descending order as the indexes change after each deletion.
     */
    const removedStepsIndexes = [
        awaitingSignOffIndex,
        enrichmentIndex,
        rightToProcessCheckIndex,
    ]
        .sort()
        .reverse();
    removedStepsIndexes.forEach(index => {
        delete mergedSteps[index];
    });

    return mergedSteps;
};

const countNumberOfCollectionsInEachStep = (
    providerStatuses: ProviderStatus[],
    groupedSteps: GroupedStep,
): GroupedStep => {
    if (!providerStatuses) {
        return groupedSteps;
    }

    providerStatuses.map(providerStatus => {
        const { latestSubmission } = providerStatus;
        if (latestSubmission === null) {
            // If there is no latest submission, then the status is "Not Started"
            // The Not Started section only has one status, hence use [0]
            groupedSteps[stepNumberForNotStarted][0].count++;
        }
        if (latestSubmission !== null) {
            const stepId = latestSubmission.status.step?.id;
            if (!stepId) {
                return;
            }
            const statuses = groupedSteps[stepId];
            const status = statuses.find(
                status => status.id === latestSubmission.status.id,
            );
            if (!status) {
                return;
            }
            const groupedStep = groupedSteps[stepId];
            groupedStep[statuses.indexOf(status)].count++;
        }
    });

    return groupedSteps;
};

interface StepSummaryProps {
    collectionReference: string;
}

const StepSummary = ({ collectionReference }: StepSummaryProps) => {
    const providerStatuses = useProviderStatuses({ collectionReference });

    const { status, data: stepsFromApi, refetch } = useSubmissionStates();

    const groupedStepsWithCount =
        status === 'success' && providerStatuses.data
            ? mergeAndRenameStepsForDisplay(
                  countNumberOfCollectionsInEachStep(
                      providerStatuses.data,
                      groupStepsByStepId(stepsFromApi),
                  ),
              )
            : null;

    const handleTryAgain = () => {
        refetch();
    };

    const renderSummary = () => {
        switch (status) {
            case 'pending':
                return <Loading />;

            case 'success':
                return groupedStepsWithCount !== null ? (
                    <div className={styles.stepSummaryBoxes}>
                        {Object.entries(groupedStepsWithCount).map(
                            ([id, statuses]) => {
                                return (
                                    <StepSummaryBox
                                        statuses={statuses}
                                        key={`step-summary-box-${id}`}
                                    />
                                );
                            },
                        )}
                    </div>
                ) : (
                    <Loading />
                );
            case 'error':
                return (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <Typography
                            variant={'body2'}
                            paragraph
                            align={'center'}
                        >
                            The step summary failed to load.
                        </Typography>
                        <Button
                            variant={'outlined'}
                            size={'small'}
                            onClick={handleTryAgain}
                        >
                            Try again
                        </Button>
                    </Box>
                );

            default:
                return null;
        }
    };

    return <div className="Summary__accordion-wrapper">{renderSummary()}</div>;
};

export default StepSummary;
