import { createContext } from 'react';

interface ToleranceInput {
    value: string;
    error: boolean;
    type: number;
}

interface EditTolerancesContextValue {
    onToleranceChange: ({ id, value }: { id: string; value: string }) => void;
    getToleranceInput: (id: string) => ToleranceInput;
    validateInput: (id: string) => void;
}

export const EditTolerancesContext =
    createContext<EditTolerancesContextValue | null>(null);
