import { useQuery } from '@tanstack/react-query';
import { GetReportDataRequestArgs } from 'services/api/credibility/types';

import { getCredibilityReportDataQueryOptions } from './options';

export default function useCredibilityReportData({
    reportCode,
    submissionId,
}: GetReportDataRequestArgs) {
    const options = getCredibilityReportDataQueryOptions({
        reportCode,
        submissionId,
    });
    return useQuery(options);
}
