import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { GetPreviousSubmissionsRequestArgs } from 'services/api/credibility/types';
import { CredibilityPreviousSubmission } from 'types/credibility';

import { getCredibilityPreviousSubmissionsQueryOptions } from './options';

export default function useCredibilityPreviousSubmissions({
    submissionId,
    collectionId,
    instId,
}: GetPreviousSubmissionsRequestArgs) {
    const options = getCredibilityPreviousSubmissionsQueryOptions({
        submissionId,
        collectionId,
        instId,
    });
    return useQuery({
        ...options,
        select: data =>
            data.map(
                s =>
                    ({
                        ...s,
                        submissionId: s.submissionId.substring(
                            s.submissionId.length - 9,
                        ),
                        fullSubmissionId: s.submissionId,
                        uploadedDate: DateTime.fromISO(s.uploadedDate).toFormat(
                            'dd/MM/yyyy HH:mm',
                        ),
                    } as CredibilityPreviousSubmission),
            ),
    });
}
