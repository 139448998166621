import React, { useCallback, useReducer } from 'react';
import { Dialog } from '@mui/material';
import {
    SpecificationInterface,
    ValidationInterface,
} from 'queries/specifications/types';

import { EditRuleMetadata } from './EditRuleMetadata/EditRuleMetadata';
import { EditTolerances } from './EditTolerances/EditTolerances';
import { EditModalContext } from './EditModalContext';

type State = {
    isOpen: boolean;
    type?: 'rule-metadata' | 'tolerances';
    id?: number;
};

type Action =
    | { type: 'rule-metadata'; id: number }
    | { type: 'tolerances'; id: number }
    | { type: 'close' };

const modalReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'rule-metadata':
            return { isOpen: true, type: 'rule-metadata', id: action.id };

        case 'tolerances':
            return { isOpen: true, type: 'tolerances', id: action.id };

        case 'close':
            return { isOpen: false };

        default:
            return state;
    }
};

const useModal = () => {
    const [state, dispatch] = useReducer(modalReducer, { isOpen: false });

    const editRuleMetadata = useCallback(
        (id: number) => dispatch({ type: 'rule-metadata', id }),
        [],
    );

    const editTolerances = useCallback(
        (id: number) => dispatch({ type: 'tolerances', id }),
        [],
    );

    const closeModal = useCallback(() => dispatch({ type: 'close' }), []);

    return {
        state,
        editRuleMetadata,
        editTolerances,
        closeModal,
    };
};

interface EditModalProps {
    spec: SpecificationInterface;
    validations: ValidationInterface[];
    children: React.ReactNode;
}

export const EditModal = ({ spec, validations, children }: EditModalProps) => {
    const { editRuleMetadata, editTolerances, closeModal, state } = useModal();

    const context = {
        editRuleMetadata,
        editTolerances,
        spec,
    };

    const handleModalClose = useCallback(closeModal, [closeModal]);
    const handleModalCancel = useCallback(closeModal, [closeModal]);

    const handleSaved = () => closeModal();

    return (
        <EditModalContext.Provider value={context}>
            {children}
            <Dialog
                className="hdp-override--specifications-spec"
                open={state.isOpen}
                onClose={handleModalClose}
            >
                <>
                    {state.type === 'rule-metadata' && (
                        <EditRuleMetadata
                            spec={spec}
                            id={state.id!}
                            onCancel={handleModalCancel}
                            onSaved={handleSaved}
                            validations={validations}
                        />
                    )}

                    {state.type === 'tolerances' && (
                        <EditTolerances
                            spec={spec}
                            id={state.id!}
                            onCancel={handleModalCancel}
                            onSaved={handleSaved}
                            validations={validations}
                        />
                    )}
                </>
            </Dialog>
        </EditModalContext.Provider>
    );
};

export default EditModal;
