import { TableCell, TableRow } from '@mui/material';
import { DateTime } from 'luxon';
import GracefulFailuresDownload from 'pages/Monitoring/SubmissionSummary/GracefulFailuresDownload';
import SubmissionLink from 'pages/Monitoring/SubmissionSummary/SubmissionLink';
import { SubmissionCollection } from 'pages/Monitoring/SubmissionSummary/utils';
import { Provider } from 'types/provider';
import { LatestSubmission } from 'types/submission';

import styles from './ovtSubmissionSummaryTable.module.scss';

const formatDate = (date: string) =>
    DateTime.fromISO(date).toFormat('dd/LL/yyyy HH:mm');

interface OvtSubmissionSummaryTableRowProps {
    provider: Provider;
    collection: SubmissionCollection;
    latestSubmission: LatestSubmission;
    className: string;
}

export default function OvtSubmissionSummaryTableRow({
    provider,
    collection,
    latestSubmission,
    className,
}: OvtSubmissionSummaryTableRowProps) {
    return (
        <TableRow hover className={className}>
            <TableCell>{provider.institutionId}</TableCell>
            <TableCell>{provider.name}</TableCell>
            <TableCell className={styles.multiLinkWrapper}>
                <SubmissionLink
                    collection={collection}
                    institutionId={provider.institutionId}
                    isOvt
                    submission={latestSubmission}
                    isNilReturn={false}
                    isHistoricResubmission={false}
                />
                <GracefulFailuresDownload
                    latestSubmission={latestSubmission}
                    isOvt
                />
            </TableCell>
            <TableCell>{formatDate(latestSubmission.uploaded)}</TableCell>
            <TableCell>{latestSubmission.studentCount}</TableCell>
        </TableRow>
    );
}
