import { useQuery } from '@tanstack/react-query';
import { GetProviderStatusesArgs } from 'services/api/submissions/types';

import { getUseProviderStatusesOptions } from './options';

interface UseProviderStatusesArgs extends GetProviderStatusesArgs {
    enabled?: boolean;
}

export default function useProviderStatuses({
    enabled = true,
    ...args
}: UseProviderStatusesArgs) {
    const options = getUseProviderStatusesOptions(args);
    return useQuery({
        ...options,
        enabled,
        select: data => data.providerStatuses,
    });
}
