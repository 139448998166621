import React, { useCallback, useEffect, useState } from 'react';
import { Box, Link, NativeSelect, NativeSelectProps } from '@mui/material';
import { RestrictAccess } from 'components';
import WithTestId from 'components/WithTestId/WithTestId';
import { collectionStates } from 'constants/collections';
import { SpecificationInterface } from 'queries/specifications/types';
import { getSpecificationVersions } from 'queries/specifications/utils';
import useUpdateSpecification from 'src/queries/collections/useUpdateSpecification';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import CancelButton from '../Buttons/CancelButton';
import EditButton from '../Buttons/EditButton';
import SaveButton from '../Buttons/SaveButton';

const Dropdown = WithTestId<NativeSelectProps>(NativeSelect);

const specificationModes = {
    UNASSIGNED: 1,
    DISPLAY: 2,
    EDITING: 3,
    SAVING: 4,
    ERROR: 5,
};

const isSpecificationEditable = (collectionState: number) => {
    return [
        collectionStates.DRAFT,
        collectionStates.VALIDATION,
        collectionStates.OPEN,
    ].includes(collectionState);
};

interface SpecificationProps {
    reference: string;
    specification: SpecificationInterface;
    specifications: SpecificationInterface[];
    collectionState: number;
    limit: number;
    offset: number;
}

export const Specification = ({
    reference,
    specification,
    specifications,
    collectionState,
    limit,
    offset,
}: SpecificationProps) => {
    const { UNASSIGNED, DISPLAY, EDITING, SAVING, ERROR } = specificationModes;

    const { mutate: updateSpecification } = useUpdateSpecification();

    const { id, name } = specification;

    const isEditable = isSpecificationEditable(collectionState);

    const specificationVersions = getSpecificationVersions(specifications);

    const [mode, setMode] = useState(id ? DISPLAY : UNASSIGNED);

    const [value, setValue] = useState(id || specificationVersions[0].id);

    useEffect(() => {
        if ([DISPLAY].includes(mode)) {
            setValue(id);
        }
    }, [DISPLAY, UNASSIGNED, id, mode]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setValue(Number(event.target.value));
    };

    const handleEdit = () => setMode(EDITING);
    const handleCancel = () => setMode(id ? DISPLAY : UNASSIGNED);
    const handleSave = useCallback(() => {
        setMode(SAVING);
        updateSpecification(
            {
                reference,
                specificationId: value,
                limit,
                offset,
            },
            {
                onSuccess: () => {
                    setMode(DISPLAY);
                },
                onError: () => {
                    setMode(ERROR);
                },
            },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reference, value]);

    const renderDropdown = ({ disabled = false, error = false } = {}) => (
        <Dropdown
            value={value}
            onChange={handleChange}
            disabled={disabled}
            error={error}
            testIdProps={{
                inputProps: {
                    'data-test-id': formatTestId(
                        'selectSpecification',
                        reference,
                    ),
                },
            }}
        >
            {specificationVersions.map(({ id, name }) => (
                <option key={id} value={id}>
                    {name}
                </option>
            ))}
        </Dropdown>
    );

    const getFallbackComponent = () => {
        return (
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'flex-start'}
            >
                No specification assigned
            </Box>
        );
    };

    const renderAssignLink = () => (
        <RestrictAccess
            allowPermissions={['management.assign-specification']}
            fallBackComponent={getFallbackComponent()}
        >
            <Link
                className="hdp-override"
                component="button"
                variant="body2"
                onClick={handleEdit}
                data-test-id={formatTestId('assign specification', reference)}
            >
                Assign specification version
            </Link>
        </RestrictAccess>
    );

    const renderEditButton = () => (
        <RestrictAccess allowPermissions={['management.assign-specification']}>
            <EditButton
                title="Change specification version"
                onClick={handleEdit}
                dataTestId={formatTestId('changeSpecification', reference)}
            />
        </RestrictAccess>
    );

    const renderSaveButton = ({ loading = false } = {}) => (
        <SaveButton
            title="Save specification version"
            onClick={handleSave}
            loading={loading}
            disabled={loading}
            dataTestId={formatTestId('saveSpecification', reference)}
        />
    );

    const renderCancelButton = ({ disabled = false } = {}) => (
        <CancelButton
            title="Cancel"
            onClick={handleCancel}
            disabled={disabled}
            dataTestId={formatTestId('cancelSpecificationChange', reference)}
        />
    );

    switch (mode) {
        case EDITING:
            return (
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    {renderDropdown()}
                    <Box>
                        {renderSaveButton()}
                        {renderCancelButton()}
                    </Box>
                </Box>
            );

        case SAVING:
            return (
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    {renderDropdown({ disabled: true })}
                    <Box>
                        {renderSaveButton({ loading: true })}
                        {renderCancelButton({ disabled: true })}
                    </Box>
                </Box>
            );

        case ERROR:
            return (
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    {renderDropdown({ error: true })}
                    <Box>
                        {renderSaveButton()}
                        {renderCancelButton()}
                    </Box>
                </Box>
            );

        case UNASSIGNED:
            return (
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                >
                    {renderAssignLink()}
                </Box>
            );

        default:
            return (
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                >
                    {name}
                    {isEditable && renderEditButton()}
                </Box>
            );
    }
};

export default Specification;
