import { SubmissionStatus } from 'types/submission';

/*
The step summary API returns this: 
"states": [
        {
            "id": null,
            "code": null,
            "name": "Not started",
            "step": {
                "id": null,
                "name": "Not started",
                "number": 1
            },
            "awaitingParty": null
        },
        {
            "id": 1,
            "code": "UPLOADING",
            "name": "Uploading",
            "step": {
                "id": 1,
                "name": "Ingestion",
                "number": 2
            },
            "awaitingParty": null
        },
        {
            "id": 2,
            "code": "UPLOADED",
            "name": "Uploaded",
            "step": {
                "id": 1,
                "name": "Ingestion",
                "number": 2
            },
            "awaitingParty": null
        },
        {
            "id": 3,
            "code": "UPLOAD_ERROR",
            "name": "Upload error",
            "step": {
                "id": 1,
                "name": "Ingestion",
                "number": 2
            },
            "awaitingParty": {
                "id": 2,
                "name": "HESA"
            }
        },
        {
            "id": 4,
            "code": "PROVISIONING",
            "name": "Provisioning",
            "step": {
                "id": 1,
                "name": "Ingestion",
                "number": 2
            },
            "awaitingParty": null
        },
        {
            "id": 5,
            "code": "PROVISIONING_ERROR",
            "name": "Provisioning error",
            "step": {
                "id": 1,
                "name": "Ingestion",
                "number": 2
            },
            "awaitingParty": {
                "id": 2,
                "name": "HESA"
            }
        },
        {
            "id": 6,
            "code": "PROVISIONED",
            "name": "Provisioned",
            "step": {
                "id": 1,
                "name": "Ingestion",
                "number": 2
            },
            "awaitingParty": null
        },
        {
            "id": 7,
            "code": "SCHEMA_CHECK",
            "name": "Schema check",
            "step": {
                "id": 2,
                "name": "Schema check",
                "number": 3
            },
            "awaitingParty": null
        },
        {
            "id": 8,
            "code": "SCHEMA_CHECK_FAILED",
            "name": "Schema check failed",
            "step": {
                "id": 2,
                "name": "Schema check",
                "number": 3
            },
            "awaitingParty": {
                "id": 1,
                "name": "Provider"
            }
        },
        {
            "id": 9,
            "code": "SCHEMA_CHECK_ERROR",
            "name": "Schema check error",
            "step": {
                "id": 2,
                "name": "Schema check",
                "number": 3
            },
            "awaitingParty": {
                "id": 2,
                "name": "HESA"
            }
        },
        {
            "id": 10,
            "code": "SCHEMA_CHECK_PASSED",
            "name": "Schema check passed",
            "step": {
                "id": 2,
                "name": "Schema check",
                "number": 3
            },
            "awaitingParty": null
        },
        {
            "id": 11,
            "code": "ENRICHMENT",
            "name": "Enrichment",
            "step": {
                "id": 3,
                "name": "Enrichment",
                "number": 5
            },
            "awaitingParty": null
        },
        {
            "id": 12,
            "code": "ENRICHMENT_ERROR",
            "name": "Enrichment error",
            "step": {
                "id": 3,
                "name": "Enrichment",
                "number": 5
            },
            "awaitingParty": {
                "id": 2,
                "name": "HESA"
            }
        },
        {
            "id": 13,
            "code": "ENRICHMENT_PASSED",
            "name": "Enrichment performed",
            "step": {
                "id": 3,
                "name": "Enrichment",
                "number": 5
            },
            "awaitingParty": null
        },
        {
            "id": 14,
            "code": "SAVING_ENTITIES_ERROR",
            "name": "Processing error",
            "step": {
                "id": 3,
                "name": "Enrichment",
                "number": 5
            },
            "awaitingParty": null
        },
        {
            "id": 15,
            "code": "QUALITY_RULES",
            "name": "Quality rules",
            "step": {
                "id": 4,
                "name": "Quality assurance",
                "number": 6
            },
            "awaitingParty": null
        },
        {
            "id": 16,
            "code": "QUALITY_RULES_ERROR",
            "name": "Quality rules error",
            "step": {
                "id": 4,
                "name": "Quality assurance",
                "number": 6
            },
            "awaitingParty": {
                "id": 2,
                "name": "HESA"
            }
        },
        {
            "id": 17,
            "code": "QUALITY_RULES_PASSED",
            "name": "Quality reports generated",
            "step": {
                "id": 4,
                "name": "Quality assurance",
                "number": 6
            },
            "awaitingParty": null
        },
        {
            "id": 18,
            "code": "CREDIBILITY",
            "name": "Credibility reports",
            "step": {
                "id": 4,
                "name": "Quality assurance",
                "number": 6
            },
            "awaitingParty": null
        },
        {
            "id": 19,
            "code": "CREDIBILITY_ERROR",
            "name": "Credibility reports error",
            "step": {
                "id": 4,
                "name": "Quality assurance",
                "number": 6
            },
            "awaitingParty": {
                "id": 2,
                "name": "HESA"
            }
        },
        {
            "id": 20,
            "code": "CREDIBILITY_PASSED",
            "name": "Credibility reports generated",
            "step": {
                "id": 4,
                "name": "Quality assurance",
                "number": 6
            },
            "awaitingParty": null
        },
        {
            "id": 21,
            "code": "COLLECTION_REPORTS",
            "name": "Generating collection reports",
            "step": {
                "id": 4,
                "name": "Quality assurance",
                "number": 6
            },
            "awaitingParty": null
        },
        {
            "id": 22,
            "code": "COLLECTION_REPORTS_ERROR",
            "name": "Collection reports generation error",
            "step": {
                "id": 4,
                "name": "Quality assurance",
                "number": 6
            },
            "awaitingParty": {
                "id": 2,
                "name": "HESA"
            }
        },
        {
            "id": 23,
            "code": "COLLECTION_REPORTS_PASSED",
            "name": "Collection reports generated",
            "step": {
                "id": 4,
                "name": "Quality assurance",
                "number": 6
            },
            "awaitingParty": null
        },
        {
            "id": 24,
            "code": "QUALITY_PROCESSING_COMPLETE",
            "name": "Quality processing complete",
            "step": {
                "id": 4,
                "name": "Quality assurance",
                "number": 6
            },
            "awaitingParty": {
                "id": 1,
                "name": "Provider"
            }
        },
        {
            "id": 25,
            "code": "HESA_ANALYST_APPROVAL",
            "name": "Awaiting HESA analyst approval",
            "step": {
                "id": 5,
                "name": "Approval",
                "number": 7
            },
            "awaitingParty": {
                "id": 2,
                "name": "HESA"
            }
        },
        {
            "id": 26,
            "code": "HESA_APPROVAL",
            "name": "Awaiting HESA approval",
            "step": {
                "id": 5,
                "name": "Approval",
                "number": 7
            },
            "awaitingParty": {
                "id": 2,
                "name": "HESA"
            }
        },
        {
            "id": 27,
            "code": "SC_APPROVAL",
            "name": "Awaiting SC approval",
            "step": {
                "id": 5,
                "name": "Approval",
                "number": 7
            },
            "awaitingParty": {
                "id": 3,
                "name": "Statutory Customer"
            }
        },
        {
            "id": 28,
            "code": "APPROVED",
            "name": "Approved",
            "step": {
                "id": 5,
                "name": "Approval",
                "number": 7
            },
            "awaitingParty": {
                "id": 1,
                "name": "Provider"
            }
        },
        {
            "id": 29,
            "code": "PROVIDER_SIGNOFF",
            "name": "Awaiting provider sign off",
            "step": {
                "id": 6,
                "name": "Awaiting sign off",
                "number": 8
            },
            "awaitingParty": {
                "id": 2,
                "name": "HESA"
            }
        },
        {
            "id": 30,
            "code": "SIGNED_OFF",
            "name": "Submission signed off",
            "step": {
                "id": 7,
                "name": "Signed off",
                "number": 9
            },
            "awaitingParty": null
        },
        {
            "id": 31,
            "code": "HISTORY_DATA",
            "name": "Generating history",
            "step": {
                "id": 7,
                "name": "Signed off",
                "number": 9
            },
            "awaitingParty": null
        },
        {
            "id": 32,
            "code": "HISTORY_DATA_ERROR",
            "name": "History generation error",
            "step": {
                "id": 7,
                "name": "Signed off",
                "number": 9
            },
            "awaitingParty": {
                "id": 2,
                "name": "HESA"
            }
        },
        {
            "id": 33,
            "code": "HISTORY_DATA_PASSED",
            "name": "History generation complete",
            "step": {
                "id": 7,
                "name": "Signed off",
                "number": 9
            },
            "awaitingParty": null
        },
        {
            "id": 34,
            "code": "RESUBMISSION_REQUIRED",
            "name": "Resubmission required",
            "step": {
                "id": 5,
                "name": "Approval",
                "number": 7
            },
            "awaitingParty": {
                "id": 1,
                "name": "Provider"
            }
        },
        {
            "id": 35,
            "code": "NIL_RETURN",
            "name": "Nil return",
            "step": {
                "id": 7,
                "name": "Signed off",
                "number": 9
            },
            "awaitingParty": null
        },
        {
            "id": 36,
            "code": "RESUBMISSION_REQUIRED_HISTORIC_AMENDMENT",
            "name": "Resubmission required - historic amendment",
            "step": {
                "id": 5,
                "name": "Approval",
                "number": 7
            },
            "awaitingParty": {
                "id": 1,
                "name": "Provider"
            }
        },
        {
            "id": 37,
            "code": "RIGHT_TO_PROCESS",
            "name": "Right to process check",
            "step": {
                "id": 8,
                "name": "Right to process check",
                "number": 4
            },
            "awaitingParty": null
        },
        {
            "id": 38,
            "code": "RIGHT_TO_PROCESS_FAILED",
            "name": "Right to process check failed",
            "step": {
                "id": 8,
                "name": "Right to process check",
                "number": 4
            },
            "awaitingParty": {
                "id": 1,
                "name": "Provider"
            }
        },
        {
            "id": 39,
            "code": "RIGHT_TO_PROCESS_ERROR",
            "name": "Right to process check error",
            "step": {
                "id": 8,
                "name": "Right to process check",
                "number": 4
            },
            "awaitingParty": {
                "id": 2,
                "name": "HESA"
            }
        },
        {
            "id": 40,
            "code": "RIGHT_TO_PROCESS_PASSED",
            "name": "Right to process check passed",
            "step": {
                "id": 8,
                "name": "Right to process check",
                "number": 4
            },
            "awaitingParty": null
        }
    ]
*/
export interface StepWithCount extends SubmissionStatus {
    count: number;
}

export interface GroupedStep {
    [stepId: number]: StepWithCount[];
}

export const displayStepMapping: { [key: string]: string } = {
    'Not started': 'Not Started',
    Ingestion: 'Upload',
    'Schema check': 'Processing ',
    'Right to process check': 'Processing',
    Enrichment: 'Processing',
    'Quality assurance': 'Quality Assurance',
    Approval: 'Approval',
    'Awaiting sign off': 'Sign off',
    'Signed off': 'Sign off',
};
