import { Stream } from 'types/stream';

export const APP_SHORT_NAME = 'HDP';
export const APP_NAME = 'HESA Data Platform';

export const API_URL = import.meta.env.VITE_API_URL;
export const WS_URL = import.meta.env.VITE_WS_URL;
export const AUTH_CLIENT_URL = import.meta.env.VITE_AUTH_CLIENT_URL;
export const AUTH_CLIENT_ID = import.meta.env.VITE_AUTH_CLIENT_ID;
export const AUTH_REDIRECT_HOST = import.meta.env.VITE_AUTH_REDIRECT_HOST;

export const TABLE_PARAMS_IDS = {
    QUALITY_RULES: 'qrt',
    SUBMISSIONS_SUMMARY: 'sst',
    SUBMISSIONS_SUMMARY_OVT: 'ssovt',
    COLLECTIONS_ACTIVITY_LOG: 'calt',
    PROVIDER_ACTIVITY_LOG: 'palt',
    ONLINE_VALIDATION_QUALITYRULE: 'ovqrt',
    REPORTS_QUALITY_RULES: 'rqrt',
    DELIVERY: 'delt',
    DELIVERY_LOG: 'dellogt',
    IRIS_REPROCESSING: 'irist',
    COLLECTIONS: 'colt',
    BULK_REPROCESSING: 'brt',
    HISTORIC_AMENDMENT: 'hat',
    DELIVERY_CONFIG: 'delconft',
    SPECIFICATIONS_PANELS: 'spt',
    MONITORING: 'mont',
    REGULATOR_DELIVERY_CONFIGS: 'rdct',
    CREDIBILITY_REPORT_TABLE: 'crt',
    CREDIBILITY_REPORT_DRILLDOWN_TABLE: 'crdt',
} as const;

// check that all values in TABLE_PARAMS_IDS are unique
const tableParamsIds = Object.values(TABLE_PARAMS_IDS);
if (new Set(tableParamsIds).size !== tableParamsIds.length) {
    throw new Error('TABLE_PARAMS_IDS values must be unique');
}

export const PATHS = {
    DASHBOARD: '/',
    MONITORING: '/monitoring',
    MONITORING_REFERENCE: '/monitoring/:reference',
    DELIVERY: '/delivery',
    DELIVERY_LOG: '/delivery/downloads-log',
    SUBMISSIONS: '/submissions',
    IRIS_REPROCESSING: '/iris-reprocessing',
    ONLINE_VALIDATION: '/online-validation',
    SUBMISSION: '/submissions/:collectionId/:submissionId',
    ADMIN_SUBMISSION:
        '/monitoring/:reference/:collectionId/:instId/:submissionId',
    UPLOAD: '/submissions/:collectionId/:submissionId/upload',
    ADMIN_UPLOAD:
        '/monitoring/:reference/:collectionId/:instId/:submissionId/upload',
    ADMIN_PROCESSING:
        '/monitoring/:reference/:collectionId/:instId/:submissionId/processing',
    PROCESSING: '/submissions/:collectionId/:submissionId/processing',
    ADMIN_REPORTS:
        '/monitoring/:reference/:collectionId/:instId/:submissionId/reports',
    REPORTS: '/submissions/:collectionId/:submissionId/reports',
    PROVIDER_ACTIVITY_LOG: '/submissions/:collectionReference/activity-log',
    ADMIN_PROVIDER_SIGN_OFF:
        '/monitoring/:reference/:collectionId/:instId/:submissionId/signoff',
    PROVIDER_SIGN_OFF: '/submissions/:collectionId/:submissionId/signoff',
    ADMIN_SUBMIT:
        '/monitoring/:reference/:collectionId/:instId/:submissionId/submit',
    SUBMIT: '/submissions/:collectionId/:submissionId/submit',
    ADMIN_APPROVAL:
        '/monitoring/:reference/:collectionId/:instId/:submissionId/approval',
    APPROVAL: '/submissions/:collectionId/:submissionId/approval',
    QUALITY_RULE:
        '/submissions/:collectionId/:submissionId/reports/quality/:rule/:regulatorCode',
    ADMIN_QUALITY_RULE:
        '/monitoring/:reference/:collectionId/:instId/:submissionId/reports/quality/:rule/:regulatorCode',
    CREDIBILITY_REPORT:
        '/submissions/:collectionId/:submissionId/reports/credibility/:reportCode/:instId',
    ADMIN_CREDIBILITY_REPORT:
        '/monitoring/:reference/:collectionId/:instId/:submissionId/reports/credibility/:reportCode/:instId',
    ADDITIONAL_REPORT:
        '/submissions/:collectionId/:submissionId/reports/additional-reports/:reportCode',
    ADMIN_ADDITIONAL_REPORT:
        '/monitoring/:reference/:collectionId/:instId/:submissionId/reports/additional-reports/:reportCode',
    FREQUENCY_COUNTS_REPORT:
        '/submissions/:collectionId/:submissionId/reports/additional-reports/FREQCOUNT',
    ADMIN_FREQUENCY_COUNTS_REPORT:
        '/monitoring/:reference/:collectionId/:instId/:submissionId/reports/additional-reports/FREQCOUNT',
    PGRTRANSFERS_OUT_REPORT:
        '/submissions/:collectionId/:submissionId/reports/additional-reports/PGROUT',
    ADMIN_PGRTRANSFERS_OUT_REPORT:
        '/monitoring/:reference/:collectionId/:instId/:submissionId/reports/additional-reports/PGROUT',
    PGRTRANSFERS_IN_REPORT:
        '/submissions/:collectionId/:submissionId/reports/additional-reports/PGRIN',
    ADMIN_PGRTRANSFERS_IN_REPORT:
        '/monitoring/:reference/:collectionId/:instId/:submissionId/reports/additional-reports/PGRIN',
    CONTINUITY_POPULATION_REPORT:
        '/submissions/:collectionId/:submissionId/reports/additional-reports/CONPOP',
    ADMIN_CONTINUITY_POPULATION_REPORT:
        '/monitoring/:reference/:collectionId/:instId/:submissionId/reports/additional-reports/CONPOP',
    NSS_REPORT:
        '/submissions/:collectionId/:submissionId/reports/additional-reports/NSS',
    ADMIN_NSS_REPORT:
        '/monitoring/:reference/:collectionId/:instId/:submissionId/reports/additional-reports/NSS',
    CCA_REPORT:
        '/submissions/:collectionId/:submissionId/reports/additional-reports/CCA',
    ADMIN_CCA_REPORT:
        '/monitoring/:reference/:collectionId/:instId/:submissionId/reports/additional-reports/CCA',
    IRIS_REPORT:
        '/submissions/:collectionId/:submissionId/reports/additional-reports/IRIS',
    ADMIN_IRIS_REPORT:
        '/monitoring/:reference/:collectionId/:instId/:submissionId/reports/additional-reports/IRIS',
    MANAGEMENT: '/management',
    MANAGEMENT_COLLECTIONS: '/management/collections',
    MANAGEMENT_PROVIDERS: '/management/providers',
    MANAGEMENT_SPECIFICATIONS: '/management/specifications',
    MANAGEMENT_SPECIFICATIONS_NEW: '/management/specifications/specs/new',
    MANAGEMENT_SPECIFICATIONS_EXISTING:
        '/management/specifications/specs/:spec',
    BULK_PROCESSING:
        '/management/collections/bulk-reprocessing/:collectionId/:reference',
    HISTORIC_AMENDMENT:
        '/management/collections/historic-amendment/:collectionReference',
    DELIVERY_CONFIG: '/management/delivery-config',
    DELIVERY_CREATE_CONFIG: '/management/delivery-config/create',
    ACCESSIBILITY: '/accessibility',
} as const;

export const OVTPATHS = {
    DASHBOARD: '/',
    MONITORING: '/monitoring',
    MONITORING_REFERENCE: '/monitoring/:reference',
    DELIVERY: '/delivery',
    SUBMISSIONS: '/online-validation',
    ONLINE_VALIDATION: '/online-validation',
    SUBMISSION: '/online-validation/:collectionId/:submissionId',
    ADMIN_SUBMISSION:
        '/monitoring/:reference/:collectionId/online-validation/:instId/:submissionId',
    UPLOAD: '/online-validation/:collectionId/:submissionId/upload',
    ADMIN_UPLOAD:
        '/monitoring/:reference/:collectionId/online-validation/:instId/:submissionId/upload',
    PROCESSING: '/online-validation/:collectionId/:submissionId/processing',
    ADMIN_PROCESSING:
        '/monitoring/:reference/:collectionId/online-validation/:instId/:submissionId/processing',
    REPORTS: '/online-validation/:collectionId/:submissionId/reports',
    ADMIN_REPORTS:
        '/monitoring/:reference/:collectionId/online-validation/:instId/:submissionId/reports',
    PROVIDER_ACTIVITY_LOG: '/submissions/:collectionReference/activity-log',
    PROVIDER_SIGN_OFF: '/online-validation/:collectionId/:submissionId/signoff',
    SUBMIT: '/monitoring/:reference/:collectionId/online-validation/:instId/:submissionId/submit',
    QUALITY_RULE:
        '/online-validation/:collectionId/:submissionId/reports/quality/:rule/:regulatorCode',
    ADMIN_QUALITY_RULE:
        '/monitoring/:reference/:collectionId/online-validation/:instId/:submissionId/reports/quality/:rule/:regulatorCode',
    SPECIFICATION: '/specification',
    MANAGEMENT: '/management',
    MANAGEMENT_COLLECTIONS: '/management/collections',
    SPECIFICATIONS: '/management/specifications',
    BULK_PROCESSING: '/management/bulk-reprocessing/:collectionId/:reference',
    DELIVERY_CONFIG: '/management/delivery-config',
    DELIVERY_CREATE_CONFIG: '/management/delivery-config/create',
    ACCESSIBILITY: '/accessibility',
} as const;

export const deliveryStatus = {
    NEW: {
        id: 10,
        name: 'New',
    },
    INPROGRESS: {
        id: 20,
        name: 'In Progress...',
    },
    COMPLETED: {
        id: 30,
        name: 'Completed',
    },
    COMPLETEDWITHERRORS: {
        id: 40,
        name: 'Completed with errors',
    },
    FAILED: {
        id: 50,
        name: 'Failed',
    },
} as const;

type DeliveryStatusKey = keyof typeof deliveryStatus;

export const getStatusNameById = (id: number) => {
    for (const key in deliveryStatus) {
        if (deliveryStatus[key as DeliveryStatusKey].id === id) {
            return deliveryStatus[key as DeliveryStatusKey].name;
        }
    }
};

export const ROLES = {
    PROVIDER: '1',
    HESA: '2',
    STATUTORY_CUSTOMER: '3',
} as const;

export const roles = {
    ovt: [
        'OVT HDP submitter - Student',
        'Software Supplier OVT Submitter - Student',
    ],
    provider: [
        'Provider HDP Guest - Student',
        'Provider HDP Submitter - Student',
        'Provider HDP Sign Off - Student',
        'Provider HDP Guest - Staff',
        'Provider HDP Submitter - Staff',
        'Provider HDP Sign Off - Staff',
        'Software Supplier OVT Submitter - Student',
    ],
    hesa: [
        'HESA HDP Admin User',
        'HESA HDP Non PII View User',
        'HESA HDP Non PII Edit User',
        'HESA HDP PII View User',
        'HESA HDP PII Edit User',
        'HESA HDP Special Category View User',
        'HESA HDP Special Category Edit User',
    ],
    statutoryCustomer: [
        'Statutory Customer HDP Analyst User - Student',
        'Statutory Customer HDP Data Delivery User - Student',
        'Statutory Customer HDP ITT API User - Student',
        'Statutory Customer HDP Analyst User - Staff',
        'Statutory Customer HDP Data Delivery User - Staff',
        'Statutory Customer HDP ITT API User - Staff',
    ],
} as const;

export const defaultValidationGuidanceUrl = 'https://hesa.ac.uk';

export const FETCH_STATUS = {
    NOT_FETCHED: 1,
    FETCHING: 2,
    SUCCESS: 3,
    FAILED: 4,
} as const;

export const stepperTitles = {
    ingest: 'upload',
    reports: 'quality assurance',
    signoff: 'sign-off',
} as const;

export const OPEN_UNIVERSITY_ALL = '0001';
export const OPEN_UNIVERSITY_ENGLAND = '0364';
export const OPEN_UNIVERSITY_NORTHERN_IRELAND = '0365';
export const OPEN_UNIVERSITY_SCOTLAND = '0366';
export const OPEN_UNIVERSITY_WALES = '0367';
export const OPEN_UNIVERSITY_REGIONS = [
    OPEN_UNIVERSITY_ENGLAND,
    OPEN_UNIVERSITY_NORTHERN_IRELAND,
    OPEN_UNIVERSITY_SCOTLAND,
    OPEN_UNIVERSITY_WALES,
];

export const ACCORDION_IDS = {
    MONITORING: {
        COLLECTION_SUMMARY: 'collection-summary',
        STEP_SUMMARY: 'step-summary',
        SUBMISSION_SUMMARY: 'submission-summary',
        SUBMISSION_SUMMARY_OVT: 'submission-summary-ovt',
        ACTIVITY_LOG: 'activity-log',
    },
    SUBMISSION: {
        SUMMARY: 'summary',
        QUALITY_REPORT: 'quality-report',
        CREDIBILITY_REPORTS: 'credibility-reports',
        CREDIBILITY_REPORT_CHAPTER: 'cr-chapter-',
        ADDITIONAL_REPORTS: 'additional-report',
        SCHEMA_VALIDATION_ERRORS: 'schema-validation-errors',
    },
    MANAGE_SUBMISSIONS: {
        COLLECTION: 'collection-',
    },
    IRIS: {
        REGULATOR_DELIVERY_CONFIGS: 'regulator-delivery-configs',
    },
    SPECIFICATION: {
        VALIDATION: 'validation-',
        DERIVED_FIELD: 'derived-field-',
    },
} as const;

export const ADDITIONAL_REPORTS: Record<string, string> = {
    FREQCOUNT: 'Frequency Counts',
    CONPOP: 'Continuity Population',
    NSS: 'NSS Survey',
    CCA: 'Cost Centre Analysis',
    IRIS: 'IRIS',
} as const;

export const STREAMS: Stream[] = [
    { id: '042', name: 'Estates', available: false },
    { id: '031', name: 'Finance', available: false },
    { id: '025', name: 'Staff', available: false },
    { id: '056', name: 'Student', available: true },
];

export const REGULATOR_OFS = '5016';

export const STUDENT_STREAM_CODE = '056';
