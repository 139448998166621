import { Tooltip } from '@mui/material';
import { ReactComponent as FileExclamation } from 'assets/icons/file_exclamation.svg';
import { useUserContext } from 'components';
import { LatestSubmission } from 'types/submission';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import { requestGracefulFailuresDownload } from './utils';

import styles from './gracefulFailuresDownload.module.scss';

interface GracefulFailuresDownloadProps {
    latestSubmission: LatestSubmission;
    isOvt?: boolean;
}

const GracefulFailuresDownload = ({
    latestSubmission,
    isOvt = false,
}: GracefulFailuresDownloadProps) => {
    const { isAdmin } = useUserContext();

    if (isAdmin && latestSubmission?.errorFileExists) {
        return (
            <div className={styles.iconWrapper}>
                <Tooltip
                    title="Download error log file"
                    arrow
                    placement={'top'}
                >
                    <button
                        onClick={() =>
                            requestGracefulFailuresDownload(
                                latestSubmission.uuid,
                                isOvt,
                            )
                        }
                        className={styles.button}
                        data-test-id={formatTestId(
                            'download file',
                            'graceful failures report',
                        )}
                        aria-label="Download Graceful Failures Report"
                    >
                        <FileExclamation className={styles.icon} />
                    </button>
                </Tooltip>
            </div>
        );
    }
};

export default GracefulFailuresDownload;
