import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import { CredibilityReportDrilldown } from 'types/credibility';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import styles from './credibilityDrilldownTable.module.scss';

interface CredibilityDrilldownTableProps {
    data: CredibilityReportDrilldown;
    onSort: (column: string) => void;
    pageHandler: (offset: number, limit: number) => void;
    sortOrder?: 'asc' | 'desc';
    sortBy: string;
}
export default function CredibilityDrilldownTable({
    data,
    onSort,
    pageHandler,
    sortOrder,
    sortBy,
}: CredibilityDrilldownTableProps) {
    if (!data.DrillDown) return null;

    return (
        <>
            <TableContainer component={Paper}>
                <Table
                    className={styles.table}
                    aria-label="credibility drilldown table"
                    data-test-id={formatTestId(
                        'table',
                        'credibility drill down',
                    )}
                >
                    <TableHead>
                        <TableRow>
                            {data.DrillDown.Headers.map(col => (
                                <TableCell
                                    onClick={() => onSort(col)}
                                    key={col}
                                    className={styles.drilldownSort}
                                    style={{ minWidth: 100 }}
                                >
                                    <span className={styles.drilldownLabel}>
                                        <strong>{col}</strong>
                                        <TableSortLabel
                                            active={sortBy === col}
                                            direction={sortOrder}
                                        />
                                    </span>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.DrillDown.Data.map((row, index) => (
                            <TableRow
                                key={`drilldown-${row.join('')}-${index}`}
                                className={styles.row}
                            >
                                {row.map((cell, index) => (
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        key={`drilldown-${cell}-${index}`}
                                    >
                                        {cell}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={data.PagingMetadata?.Total || 0}
                rowsPerPage={data.PagingMetadata?.Limit || 10}
                page={
                    data.PagingMetadata?.Offset / data.PagingMetadata?.Limit ||
                    0
                }
                backIconButtonProps={{
                    'aria-label': 'previous page',
                    // 'data-test-id': 'previousPage',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                    // 'data-test-id': 'nextPage',
                }}
                onPageChange={(_, page) =>
                    pageHandler(page, data.PagingMetadata?.Limit)
                }
                onRowsPerPageChange={event =>
                    pageHandler(0, +event.target.value)
                }
            />
        </>
    );
}
