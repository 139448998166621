import { useQuery } from '@tanstack/react-query';
import { GetCompareDataArgs } from 'services/api/credibility/types';

import { getCredibilityCompareDataQueryOptions } from './options';

interface UseCredibilityCompareDataArgs extends GetCompareDataArgs {
    enabled?: boolean;
}
export default function useCredibilityCompareData({
    reportCode,
    currentSubmissionId,
    previousSubmissionId,
    enabled = true,
}: UseCredibilityCompareDataArgs) {
    const options = getCredibilityCompareDataQueryOptions({
        reportCode,
        currentSubmissionId,
        previousSubmissionId,
    });
    return useQuery({ ...options, enabled });
}
