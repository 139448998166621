import { queryOptions } from '@tanstack/react-query';
import { credibility } from 'services/api';
import {
    GetCompareDataArgs,
    GetDrillDownDataCsvArgs,
    GetDrillDownDataRequestArgs,
    GetPreviousSubmissionsRequestArgs,
    GetReportDataRequestArgs,
    GetReportDataXlsxArgs,
    GetReportsRequestArgs,
} from 'services/api/credibility/types';

export function getCredibilityReportDataQueryOptions({
    reportCode,
    submissionId,
}: GetReportDataRequestArgs) {
    return queryOptions({
        queryKey: ['credibility', 'report-data', reportCode, submissionId],
        queryFn: () => credibility.getReportData({ reportCode, submissionId }),
    });
}

export function getCredibilityReportsQueryOptions({
    submissionId,
    providerId,
}: GetReportsRequestArgs) {
    return queryOptions({
        queryKey: ['credibility', 'reports', submissionId],
        queryFn: () => credibility.getReports({ submissionId, providerId }),
    });
}

export function getCredibilityPreviousSubmissionsQueryOptions({
    submissionId,
    collectionId,
    instId,
}: GetPreviousSubmissionsRequestArgs) {
    return queryOptions({
        queryKey: [
            'credibility',
            'previous-submissions',
            submissionId,
            collectionId,
            instId,
        ],
        queryFn: () =>
            credibility.getPreviousSubmissions({
                submissionId,
                collectionId,
                instId,
            }),
    });
}

export function getCredibilityCompareDataQueryOptions({
    reportCode,
    currentSubmissionId,
    previousSubmissionId,
}: GetCompareDataArgs) {
    return queryOptions({
        queryKey: [
            'credibility',
            'compare-data',
            reportCode,
            currentSubmissionId,
            previousSubmissionId,
        ],
        queryFn: () =>
            credibility.getCompareData({
                reportCode,
                currentSubmissionId,
                previousSubmissionId,
            }),
    });
}

export function getCredibilityDrilldownDataQueryOptions({
    reportCode,
    submissionId,
    cellId,
    limit,
    offset,
    sortField,
    sortOrder,
}: GetDrillDownDataRequestArgs) {
    return queryOptions({
        queryKey: [
            'credibility',
            'drilldown-data',
            reportCode,
            submissionId,
            cellId,
            limit,
            offset,
            sortField,
            sortOrder,
        ],
        queryFn: () =>
            credibility.getDrillDownData({
                reportCode,
                submissionId,
                cellId,
                limit,
                offset,
                sortField,
                sortOrder,
            }),
    });
}

export function getCredibilityCellDownloadQueryOptions({
    submissionId,
    reportCode,
    cellId,
}: GetDrillDownDataCsvArgs) {
    return queryOptions({
        queryKey: [
            'credibility',
            'cell-download',
            submissionId,
            reportCode,
            cellId,
        ],
        queryFn: () =>
            credibility.getDrillDownDataCsv({
                submissionId,
                reportCode,
                cellId,
            }),
    });
}

export function getReportDownloadQueryOptions({
    reportCode,
    submissionId,
}: GetReportDataXlsxArgs) {
    return queryOptions({
        queryKey: ['credibility', 'report-download', reportCode, submissionId],
        queryFn: () =>
            credibility.getReportDataXlsx({ reportCode, submissionId }),
    });
}
