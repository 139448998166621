import { useContext } from 'react';

import { EditModalContext } from './EditModalContext';

export default function useEditModal() {
    const context = useContext(EditModalContext);
    if (context === null) {
        throw new Error('useEditModal must be used within a EditModalContext');
    }
    return context;
}
