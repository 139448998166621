import { useQuery } from '@tanstack/react-query';
import { GetDrillDownDataRequestArgs } from 'services/api/credibility/types';

import { getCredibilityDrilldownDataQueryOptions } from './options';

interface UseCredibilityDrilldownDataArgs extends GetDrillDownDataRequestArgs {
    enabled?: boolean;
}

export default function useCredibilityDrilldownData({
    reportCode,
    submissionId,
    cellId,
    limit,
    offset,
    sortField,
    sortOrder,
    enabled = true,
}: UseCredibilityDrilldownDataArgs) {
    const options = getCredibilityDrilldownDataQueryOptions({
        reportCode,
        submissionId,
        cellId,
        limit,
        offset,
        sortField,
        sortOrder,
    });
    return useQuery({ ...options, enabled });
}
