import { MouseEvent } from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';

import styles from './ovtSubmissionSummaryTable.module.scss';

const headCells = [
    { id: 'id', label: 'ID', sortable: false },
    { id: 'provider', label: 'Provider', sortable: false },
    { id: 'current-state', label: 'Current submission state', sortable: false },
    { id: 'last-submission', label: 'Last submission', sortable: true },
    { id: 'student-count', label: 'Student count', sortable: false },
];

interface OvtSubmissionSummaryTableHeadProps {
    orderBy: string;
    order: 'asc' | 'desc';
    onSort: (orderBy: string) => void;
}

export default function OvtSubmissionSummaryTableHead({
    orderBy,
    order,
    onSort,
}: OvtSubmissionSummaryTableHeadProps) {
    const renderCell = ({
        id,
        label,
        sortable,
    }: {
        id: string;
        label: string;
        sortable: boolean;
    }) => {
        const handleClick =
            (id: string) => (_event: MouseEvent<HTMLSpanElement>) => {
                onSort(id);
            };

        if (!sortable) {
            return <TableCell key={id}>{label}</TableCell>;
        }

        return (
            <TableCell key={id}>
                <TableSortLabel
                    active={id === orderBy}
                    direction={id === orderBy ? order : 'desc'}
                    onClick={handleClick(id)}
                >
                    {label}
                </TableSortLabel>
            </TableCell>
        );
    };

    return (
        <TableHead>
            <TableRow className={styles.header}>
                {headCells.map(renderCell)}
            </TableRow>
        </TableHead>
    );
}
