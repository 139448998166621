import React, { SyntheticEvent } from 'react';
import { AutocompleteChangeReason } from '@mui/material';
import AutocompleteDropdown from 'components/AutocompleteDropdown/AutocompleteDropdown';
import { AutocompleteOption } from 'components/AutocompleteDropdown/AutocompleteDropdown';

import { AwaitingParty, SubmissionHydrated } from '../utils';

import styles from './filters.module.scss';

interface ActionsMap {
    [key: string]: AwaitingParty & { count: number };
}

interface AwaitingActionsFilterProps {
    onChange: (
        event: SyntheticEvent,
        value: string[],
        reason: AutocompleteChangeReason,
    ) => void;
    submissions: SubmissionHydrated[];
    value: string[];
}

const AwaitingActionsFilter = ({
    onChange,
    submissions,
    value,
}: AwaitingActionsFilterProps) => {
    const sortAlphabetically = (a: AwaitingParty, b: AwaitingParty) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
    };

    const actionsMap: ActionsMap = submissions.reduce(
        (acc: ActionsMap, { awaitingParty }) => {
            if (!awaitingParty) return acc;
            if (acc[awaitingParty.id]) {
                acc[awaitingParty.id].count++;
            } else {
                acc[awaitingParty.id] = { ...awaitingParty, count: 1 };
            }
            return acc;
        },
        {},
    );

    const actionOptions: AutocompleteOption[] = Object.values(actionsMap)
        .sort(sortAlphabetically)
        .map(action => ({
            value: action.id,
            label: `${action.name} (${action.count})`,
        }));

    const handleChange = (
        event: SyntheticEvent,
        newValue: string[],
        reason: AutocompleteChangeReason,
    ) => {
        onChange(event, newValue, reason);
    };

    return (
        <div className={styles.filterContainer}>
            <AutocompleteDropdown
                id="awaiting-actions-filter"
                placeholder="Filter by awaiting action"
                value={value}
                options={actionOptions}
                onChange={handleChange}
                label="Filter by awaiting action"
            />
        </div>
    );
};

export default AwaitingActionsFilter;
