import { useMemo } from 'react';
import Loading from 'components/Loading/Loading';
import TabPanel from 'components/Tabs/TabPanel';

import { queryStates } from '../utils';

import SubmissionSummaryTable from './SubmissionSummaryTable/SubmissionSummaryTable';
import { useProviderSubmissions } from './hooks';

interface SubmissionSummaryProps {
    collectionReference: string;
}

export const SubmissionSummary = ({
    collectionReference,
}: SubmissionSummaryProps) => {
    const { status, submissions } = useProviderSubmissions({
        collectionReference,
    });

    const submissionsWithStates = useMemo(() => {
        return (
            submissions?.map(submission => ({
                ...submission,
                state: submission?.latestSubmission?.status?.id || 0,
            })) || []
        );
    }, [submissions]);

    return (
        <>
            {status === queryStates.LOADING && <Loading />}
            {status === queryStates.LOADED && (
                <>
                    <TabPanel value={0} index={0}>
                        <SubmissionSummaryTable
                            dataFromApi={submissionsWithStates}
                        />
                    </TabPanel>
                </>
            )}
        </>
    );
};

SubmissionSummary.displayName = 'SubmissionSummary';
