import { useQuery } from '@tanstack/react-query';
import { GetActivityLogRequestArgs } from 'services/api/collections/types';

import { getUseCollectionActivityLogOptions } from './options';

export default function useCollectionActivityLog(
    args: GetActivityLogRequestArgs,
) {
    const options = getUseCollectionActivityLogOptions(args);
    return useQuery(options);
}
