import { TableCell, TableRow } from '@mui/material';
import { Provider } from 'types/provider';

interface NotStartedSubmissionSummaryTableRowProps {
    provider: Provider;
    className: string;
}

export default function NotStartedSubmissionSummaryTableRow({
    provider,
    className,
}: NotStartedSubmissionSummaryTableRowProps) {
    return (
        <TableRow hover className={className}>
            <TableCell>{provider?.institutionId}</TableCell>
            <TableCell>{provider?.name}</TableCell>
            <TableCell>Not started</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
        </TableRow>
    );
}
