import { Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import styles from './editButton.module.scss';

interface EditButtonProps {
    title?: string;
    onClick: () => void;
    dataTestId?: string;
}

export default function EditButton({
    title,
    onClick,
    dataTestId,
}: EditButtonProps) {
    return (
        <Tooltip title={title} arrow placement="top">
            <IconButton
                className={styles.editButton}
                onClick={onClick}
                size="large"
                data-test-id={dataTestId}
            >
                <Edit />
            </IconButton>
        </Tooltip>
    );
}
