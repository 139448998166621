import useApproveSubmission from 'queries/submissions/useApproveSubmission';
import useCreateNilReturn from 'queries/submissions/useCreateNilReturn';
import useRequestResubmission from 'queries/submissions/useRequestResubmission';
import useSignOffSubmission from 'queries/submissions/useSignOffSubmission';
import { Collection } from 'types/collection';
import { Provider } from 'types/provider';
import { LatestSubmission } from 'types/submission';

interface UseSubmissionSummaryActionsArgs {
    latestSubmission: LatestSubmission;
    provider: Provider;
    collection: Collection;
}

export const useSubmissionSummaryActions = ({
    latestSubmission,
    provider,
    collection,
}: UseSubmissionSummaryActionsArgs) => {
    const approve = useApproveSubmission({
        latestSubmission,
    });

    const createNilReturn = useCreateNilReturn({
        instId: provider?.institutionId,
        collectionId: collection?.id,
    });

    const signOff = useSignOffSubmission({
        submissionId: latestSubmission?.id,
    });

    const requestResubmission = useRequestResubmission({
        uuid: latestSubmission?.uuid,
    });

    return { approve, createNilReturn, signOff, requestResubmission };
};
