import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import { TABLE_PARAMS_IDS } from 'constants/constants';
import useTableParams from 'hooks/useTableParams/useTableParams';
import useCredibilityCellDownload from 'queries/credibility/useCredibilityCellDownload';
import useCredibilityDrilldownData from 'queries/credibility/useCredibilityDrilldownData';

import CredibilityDrilldownTable from './CredibilityDrilldownTable';
import { createDownloadLinkAndClickIt } from 'pages/Collections/OnlineValidation/utils';

interface CredibilityDrilldownPopupProps {
    isOpen: boolean;
    onClose: () => void;
    cellId?: string;
    submissionId: string;
    reportCode: string;
}

export default function CredibilityDrilldownPopup({
    isOpen,
    cellId,
    submissionId,
    reportCode,
    onClose,
}: CredibilityDrilldownPopupProps) {
    const DEFAULT_TABLE_PARAMETERS = {
        offset: 0,
        limit: 10,
        sortBy: '',
        sortOrder: 'desc',
    };

    const tableId = TABLE_PARAMS_IDS.CREDIBILITY_REPORT_DRILLDOWN_TABLE;

    const { values: tableParams, methods: tableMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const { limit, offset, sortBy, sortOrder } = tableParams;
    const { setLimit, setOffset, setSortBy, setSortOrder } = tableMethods;

    const isAbleToFetchDrillDownData = Boolean(cellId) && isOpen;

    const { data: drilldownData, isFetching: isFetchingDrilldownData } =
        useCredibilityDrilldownData({
            cellId: cellId!,
            enabled: isAbleToFetchDrillDownData,
            sortField: sortBy,
            sortOrder,
            submissionId,
            reportCode,
            limit,
            offset,
        });

    const { data: cellDownloadData } = useCredibilityCellDownload({
        submissionId,
        reportCode,
        cellId: cellId!,
        enabled: isAbleToFetchDrillDownData,
    });

    const onClickConfirm = () => {
        if (cellDownloadData) {
            createDownloadLinkAndClickIt(
                cellDownloadData.href,
                cellDownloadData.download,
            );
        }
    };

    const onCancel = () => {
        setOffset(0);
        setLimit(10);
        setSortBy('');
        setSortOrder('desc');
        onClose();
    };

    const handleSort = (column: string) => {
        let newSortOrder = sortOrder;
        if (sortBy === column) {
            if (sortOrder === 'asc') {
                newSortOrder = 'desc';
            } else {
                newSortOrder = 'asc';
            }
        }

        setSortOrder(newSortOrder);
        setSortBy(column);
        setOffset(0);
    };

    const pageHandler = (offset: number, limit: number) => {
        setOffset(offset);
        setLimit(limit);
    };

    return (
        <ConfirmationPopup
            isLoading={isFetchingDrilldownData}
            onCancel={onCancel}
            onConfirm={onClickConfirm}
            isOpen={isOpen}
            title={
                drilldownData?.DrillDown?.UpperNotes
                    ? drilldownData?.DrillDown?.UpperNotes
                    : 'Drilldown Data'
            }
            size="lg"
            customConfirmButtonText="Download to CSV"
            isConfirmButtonHidden={
                !cellDownloadData?.href || !cellDownloadData?.download
            }
        >
            {!(isFetchingDrilldownData || !drilldownData) && (
                <CredibilityDrilldownTable
                    data={drilldownData}
                    onSort={handleSort}
                    pageHandler={pageHandler}
                    sortBy={sortBy}
                    sortOrder={sortOrder as 'asc' | 'desc'}
                />
            )}
        </ConfirmationPopup>
    );
}
