import classNames from 'classnames';
import { CredibilityReportCellData } from 'types/credibility';

import CellInterior from './CellInterior';

import styles from './credibilityCell.module.scss';

interface CredibilityCellProps {
    colour: string | null;
    label: string | null;
    cellId: string;
    cellType: 'Label' | 'Data';
    cellData?: CredibilityReportCellData;
    isRow?: boolean;
    isExpanded?: boolean;
    clickHandler?: (cellId: string) => void;
    canDrillDown?: boolean;
}

export const CredibilityCell = ({
    colour,
    cellType,
    label,
    clickHandler,
    cellId,
    isRow = false,
    isExpanded = false,
    cellData,
    canDrillDown = false,
}: CredibilityCellProps) => {
    const cellBackground = classNames({
        [styles.border]: true,
        [styles.yellowBackground]: colour === 'Yellow',
        [styles.whiteBackground]: colour === 'Clear',
        [styles.headerBackground]: colour === 'HeaderColour',
        [styles.salmonBackground]: colour === 'Salmon',
    });

    const header = classNames({
        [styles.rowHeader]: isRow,
        [styles.header]: true,
    });

    const scope = isRow ? 'row' : 'col';

    if (cellType === 'Label') {
        if (label) {
            return (
                <th className={`${cellBackground} ${header}`} scope={scope}>
                    {label}
                </th>
            );
        }
        return <td className={cellBackground}></td>;
    }

    return (
        <CellInterior
            className={cellBackground}
            cellData={cellData!}
            cellId={cellId}
            isExpanded={isExpanded}
            clickHandler={clickHandler!}
            canDrillDown={canDrillDown}
        />
    );
};
