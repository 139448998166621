import { Check } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';

interface SaveButtonProps {
    title?: string;
    loading?: boolean;
    disabled?: boolean;
    onClick: () => void;
    dataTestId?: string;
}

export default function SaveButton({
    onClick,
    loading = false,
    disabled = false,
    dataTestId,
}: SaveButtonProps) {
    return (
        <IconButton
            onClick={onClick}
            disabled={loading || disabled}
            color={'primary'}
            size="large"
            data-test-id={dataTestId}
        >
            {loading ? (
                <CircularProgress
                    size={16}
                    aria-label="Circular loading animation"
                />
            ) : (
                <Check />
            )}
        </IconButton>
    );
}
