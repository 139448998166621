import CheckCircle from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {
    displayStepMapping,
    StepWithCount,
} from 'pages/Monitoring/StepSummary/types';

import styles from './stepSummaryBox.module.scss';

interface StepSummaryBoxProps {
    statuses: StepWithCount[];
}

// Get the icon based on the status code of the status
const getIconFromMapping = (code: string) => {
    switch (code) {
        case null:
            return <></>;
        case 'UPLOADING':
            return <></>;
        case 'UPLOADED':
            return (
                <span className={styles.iconSuccess}>
                    <CheckCircle />
                </span>
            );
        case 'UPLOAD_ERROR ':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'PROVISIONING':
            return <></>;
        case 'PROVISIONING_ERROR':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'PROVISIONED':
            return (
                <span className={styles.iconSuccess}>
                    <CheckCircle />
                </span>
            );
        case 'SCHEMA_CHECK':
            return <></>;
        case 'SCHEMA_CHECK_FAILED':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'SCHEMA_CHECK_ERROR':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'SCHEMA_CHECK_PASSED':
            return (
                <span className={styles.iconSuccess}>
                    <CheckCircle />
                </span>
            );
        case 'ENRICHMENT':
            return <></>;
        case 'ENRICHMENT_ERROR':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'ENRICHMENT_PASSED':
            return (
                <span className={styles.iconSuccess}>
                    <CheckCircle />
                </span>
            );
        case 'SAVING_ENTITIES_ERROR':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'QUALITY_RULES':
            return <></>;
        case 'QUALITY_RULES_ERROR':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'QUALITY_RULES_PASSED':
            return (
                <span className={styles.iconSuccess}>
                    <CheckCircle />
                </span>
            );
        case 'CREDIBILITY':
            return <></>;
        case 'CREDIBILITY_ERROR':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'CREDIBILITY_PASSED':
            return (
                <span className={styles.iconSuccess}>
                    <CheckCircle />
                </span>
            );
        case 'COLLECTION_REPORTS':
            return (
                <span className={styles.iconSuccess}>
                    <CheckCircle />
                </span>
            );
        case 'COLLECTION_REPORTS_ERROR':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'COLLECTION_REPORTS_PASSED':
            return (
                <span className={styles.iconSuccess}>
                    <CheckCircle />
                </span>
            );
        case 'QUALITY_PROCESSING_COMPLETE':
            return (
                <span className={styles.iconSuccess}>
                    <CheckCircle />
                </span>
            );
        case 'HESA_ANALYST_APPROVAL':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'HESA_APPROVAL':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'SC_APPROVAL':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'APPROVED':
            return (
                <span className={styles.iconSuccess}>
                    <CheckCircle />
                </span>
            );
        case 'PROVIDER_SIGNOFF':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'SIGNED_OFF':
            return (
                <span className={styles.iconSuccess}>
                    <CheckCircle />
                </span>
            );
        case 'HISTORY_DATA':
            return <></>;
        case 'HISTORY_DATA_ERROR':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'HISTORY_DATA_PASSED':
            return (
                <span className={styles.iconSuccess}>
                    <CheckCircle />
                </span>
            );
        case 'RESUBMISSION_REQUIRED':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'NIL_RETURN':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'RESUBMISSION_REQUIRED_HISTORIC_AMENDMENT':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'RIGHT_TO_PROCESS':
            return <></>;
        case 'RIGHT_TO_PROCESS_FAILED':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'RIGHT_TO_PROCESS_ERROR':
            return (
                <span className={styles.iconError}>
                    <ErrorIcon />
                </span>
            );
        case 'RIGHT_TO_PROCESS_PASSED':
            return (
                <span className={styles.iconSuccess}>
                    <CheckCircle />
                </span>
            );
        default:
            return <></>;
    }
};

const getTotalCountForStep = (statuses: StepWithCount[]) => {
    return statuses.reduce((acc, status) => acc + status.count, 0);
};

const getDisplayNameOfStep = (stepName: string) => {
    const displayName = displayStepMapping[stepName];
    return displayName !== undefined ? displayName : stepName;
};

const StepSummaryBox = ({ statuses }: StepSummaryBoxProps) => {
    // get the stepSummary details from the step object.
    // Each status in the list has the same step object so we just use the first one.
    const stepSummary = statuses[0].step;

    const totalCount = getTotalCountForStep(statuses);

    return (
        <div className={styles.root}>
            <h2 className={styles.heading}>
                <span className={styles.name}>
                    {getDisplayNameOfStep(stepSummary.name)}
                </span>
                <span className={styles.count}>{totalCount}</span>
            </h2>
            {statuses.map(
                (step: StepWithCount) =>
                    step.count > 0 && (
                        <p
                            className={styles.stepDetailField}
                            key={`stepdetailfield-${step.code}`}
                        >
                            {getIconFromMapping(step.code)}
                            <span className={styles.name}>{step.name}</span>
                            <span className={styles.count}>{step.count}</span>
                        </p>
                    ),
            )}
        </div>
    );
};

export default StepSummaryBox;
