import { get, put } from '../utils';

import {
    GetActivityLogRequestArgs,
    GetActivityLogResponse,
    GetAllCollectionsReturnType,
} from './types';

interface GetActivityLogEntries {
    submissionUuid: string;
    activityLogTypeId: number;
}

interface UpdateSpecification {
    reference: string;
    specificationId: number;
}

interface UpdateState {
    reference: string;
    stateId: number;
}

export const getActivityLog = async ({
    collectionReference,
    limit,
    offset,
    instId,
}: GetActivityLogRequestArgs) => {
    let url = `/collections/activity-log?collectionReference=${collectionReference}&limit=${limit}&offset=${offset}`;
    if (instId) {
        url += `&instId=${instId}`;
    }
    return get(url) as Promise<GetActivityLogResponse>;
};

export const getActivityLogEntries = async ({
    submissionUuid,
    activityLogTypeId,
}: GetActivityLogEntries) =>
    get('/collections/activity-log-entries', {
        query: { submissionUuid, activityLogTypeId },
    });

interface GetAllCollections {
    offset?: number;
    limit?: number;
}

export const getAllCollections = (
    { offset, limit }: GetAllCollections = { offset: 0, limit: 1000 },
) =>
    get('/collections', {
        query: { offset, limit },
    }) as Promise<GetAllCollectionsReturnType>;

export const updateSpecification = async ({
    reference,
    specificationId,
}: UpdateSpecification) =>
    put(`/collections/${reference}`, {
        body: { specificationId },
    });

export const updateState = async ({ reference, stateId }: UpdateState) =>
    put(`/collections/${reference}/state`, {
        body: { stateId },
    });
