import { useQuery } from '@tanstack/react-query';
import { GetDrillDownDataCsvArgs } from 'services/api/credibility/types';

import { getCredibilityCellDownloadQueryOptions } from './options';

interface UseCredibilityCellDownloadArgs extends GetDrillDownDataCsvArgs {
    enabled?: boolean;
}

export default function useCredibilityCellDownload({
    submissionId,
    reportCode,
    cellId,
    enabled = true,
}: UseCredibilityCellDownloadArgs) {
    const options = getCredibilityCellDownloadQueryOptions({
        submissionId,
        reportCode,
        cellId,
    });
    return useQuery({
        ...options,
        select: data => ({
            href: `data:text/csv;charset=utf-8,${encodeURIComponent(
                data.text,
            )}`,
            download: data.fileName,
        }),
        enabled,
    });
}
