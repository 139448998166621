import Approval from './approval';
import Processing from './processing';
import QualityAssurance from './quality-assurance';
import SignOff from './sign-off';
import Submit from './submit';
import Upload from './upload';

export default {
    Upload,
    Processing,
    'Quality Assurance': QualityAssurance,
    Submit,
    Approval,
    'Sign Off': SignOff,
};
