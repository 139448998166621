import { queryOptions } from '@tanstack/react-query';
import { submissions } from 'services/api';
import {
    GetCompareSubmissionsValidationsArgs,
    GetLatestSubmissionArgs,
    GetProviderStatusesArgs,
    GetSchemaChecksArgs,
    GetSubmissionCommitAllowedStatusArgs,
    GetSubmissionsArgs,
    GetSubmissionValidationDetailsArgs,
    GetSubmissionValidationsArgs,
} from 'services/api/submissions/types';

export const getUseProviderStatusesOptions = (
    args: GetProviderStatusesArgs,
) => {
    return queryOptions({
        queryKey: ['submissions', 'provider-statuses', args],
        queryFn: () => submissions.getProviderStatuses(args),
        select: data => data.providerStatuses,
    });
};

export const getUseSchemaChecksOptions = (args: GetSchemaChecksArgs) => {
    return queryOptions({
        queryKey: ['submissions', 'schema-checks', args],
        queryFn: () => submissions.getSchemaChecks(args),
    });
};

export const getUseSubmissionValidationsOptions = (
    args: GetSubmissionValidationsArgs,
) => {
    return queryOptions({
        queryKey: ['submissions', 'validations', args],
        queryFn: () => submissions.getValidations(args),
    });
};

export const getUseCompareValidationsOptions = (
    args: GetCompareSubmissionsValidationsArgs,
) => {
    return queryOptions({
        queryKey: ['submissions', 'compare-validations', args],
        queryFn: () => submissions.getCompareValidations(args),
    });
};

export const getUseValidationDetailsOptions = (
    args: GetSubmissionValidationDetailsArgs,
) => {
    return queryOptions({
        queryKey: ['submissions', 'validation-details', args],
        queryFn: () => submissions.getValidationDetails(args),
    });
};

export const getUseStatesOptions = () => {
    return queryOptions({
        queryKey: ['submissions', 'states'],
        queryFn: () => submissions.getStates(),
    });
};

export const getUseSubmissionOptions = ({
    submissionId,
    isOvt = false,
}: {
    submissionId: string;
    isOvt: boolean;
}) => {
    return queryOptions({
        queryKey: ['submissions', 'submission', submissionId],
        queryFn: () => submissions.getSubmission({ submissionId, isOvt }),
    });
};

export const getUseSubmissionHistoryOptions = ({
    submissionId,
}: {
    submissionId: string;
}) => {
    return queryOptions({
        queryKey: ['submissions', 'submission-history', submissionId],
        queryFn: () => submissions.getSubmissionHistory({ submissionId }),
    });
};

export const getUseLatestSubmissionOptions = (
    args: GetLatestSubmissionArgs,
) => {
    return queryOptions({
        queryKey: ['submissions', 'latest-submission', args],
        queryFn: () => submissions.getLatestSubmission(args),
    });
};

export const getUseCommitAllowedStatusOptions = (
    args: GetSubmissionCommitAllowedStatusArgs,
) => {
    return queryOptions({
        queryKey: ['submissions', 'commit-allowed-status', args],
        queryFn: () => submissions.getSubmissionCommitAllowedStatus(args),
    });
};

export const getUseSubmissionsOptions = (args: GetSubmissionsArgs) => {
    return queryOptions({
        queryKey: ['submissions', 'list', args],
        queryFn: () => submissions.getSubmissions(args),
    });
};

export const getUseFailureSummaryTableOptions = ({
    submissionId,
    instId,
}: {
    submissionId: string;
    instId: string;
}) => {
    return queryOptions({
        queryKey: ['submissions', 'failure-summary', submissionId, instId],
        queryFn: () =>
            submissions.getFailureSummaryTable({
                submissionId,
                instId,
            }),
    });
};

export const getUseFileStatisticsOptions = ({
    submissionId,
    instId,
}: {
    submissionId: string;
    instId: string;
}) => {
    return queryOptions({
        queryKey: ['submissions', 'file-statistics', submissionId, instId],
        queryFn: () =>
            submissions.getFileStatistics({
                submissionId,
                instId,
            }),
    });
};
