import { useQuery } from '@tanstack/react-query';

import { getUseStatesOptions } from './options';

export default function useSubmissionStates({
    enabled = true,
}: {
    enabled?: boolean;
} = {}) {
    const options = getUseStatesOptions();
    return useQuery({
        ...options,
        enabled,
        select: data => data.states,
    });
}
