import { InfoOutlined } from '@mui/icons-material';
import { ReactComponent as DownArrow } from 'assets/icons/down_arrow.svg';
import { ReactComponent as DrillDownCorner } from 'assets/icons/drill_down_corner.svg';
import { ReactComponent as Exclamation } from 'assets/icons/error.svg';
import { ReactComponent as SalmonSquare } from 'assets/icons/salmon_square.svg';
import { ReactComponent as UpArrow } from 'assets/icons/up_arrow.svg';
import { ReactComponent as YellowSquare } from 'assets/icons/yellow_square.svg';

import styles from './symbolsLegend.module.scss';

export default function SymbolsLegend() {
    return (
        <div>
            <p>Symbols:</p>
            <ul className={styles.list}>
                <li>
                    <span>
                        <Exclamation aria-label="new failure icon" role="img" />
                    </span>
                    <span>
                        Current submission fails a shading rule where the
                        selected submission did not.
                    </span>
                </li>
                <li>
                    <span className={styles.icon}>
                        <UpArrow aria-label="value increase icon" role="img" />
                        <DownArrow
                            aria-label="value decrease icon"
                            role="img"
                        />
                    </span>
                    <span>
                        Value has increased/decreased between submissions - view
                        more details about how much the increase/decrease is.
                    </span>
                </li>
                <li>
                    <span>
                        <InfoOutlined
                            aria-label="rule information icon"
                            aria-hidden="false"
                            role="img"
                        />
                    </span>
                    <span>
                        View more information about why this cell is shaded.
                    </span>
                </li>
                <li>
                    <span>
                        <DrillDownCorner
                            aria-label="drill down available icon"
                            role="img"
                        />
                    </span>
                    <span>See the detailed data behind this cell total.</span>
                </li>
                <li>
                    <span>
                        <YellowSquare />
                    </span>
                    <span>
                        Data has failed a credibility rule in the current
                        submission and failed that same rule in the compared
                        submission.
                    </span>
                </li>
                <li>
                    <span>
                        <SalmonSquare />
                    </span>
                    <span>
                        Data has failed a credibility rule in the current
                        submission, but did not fail that rule in the compared
                        submission.
                    </span>
                </li>
            </ul>
        </div>
    );
}
