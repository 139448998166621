import { ReactNode } from 'react';
import { Container, Typography } from '@mui/material';

const ErrorMessage = ({
    title,
    children,
}: {
    title: string;
    children: ReactNode;
}) => (
    <Container maxWidth={'sm'}>
        <Typography variant={'h6'} align={'left'} gutterBottom>
            {title}
        </Typography>
        {children}
    </Container>
);

export default ErrorMessage;
