import React, { useEffect, useRef, useState } from 'react';
import { ArrowDropDownSharp } from '@mui/icons-material';
import {
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@mui/material';

import styles from './columnSelectList.module.scss';

interface ColumnSelectListProps<Item extends object> {
    closeDropDown: (item: Item) => void;
    list: Item[];
    columnNames: string[];
    columnKeys: (keyof Item)[];
    disabled?: boolean;
    selectedValue?: Item | null;
    placeholder: string;
}

export const ColumnSelectList = <Item extends object>({
    closeDropDown,
    list,
    columnNames,
    columnKeys,
    disabled,
    selectedValue,
    placeholder,
}: ColumnSelectListProps<Item>) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const prevOpen = useRef(open);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClickAway = (event: MouseEvent | TouchEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as Node)
        ) {
            return;
        }

        setOpen(false);
    };

    const handleClose = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        item: Item,
    ) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as Node)
        ) {
            return;
        }

        if (item) {
            closeDropDown(item);
        }

        setOpen(false);
    };

    const listItemDisplay = (item: Item) => (
        <>
            {columnKeys.map(key => (
                <span className={styles.listItemText} key={crypto.randomUUID()}>
                    {item[key as keyof Item] as string}
                </span>
            ))}
        </>
    );

    useEffect(() => {
        if (prevOpen.current === true && open === false && anchorRef.current) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={styles.wrapper}>
            <button
                ref={anchorRef}
                onClick={handleToggle}
                className={styles.button}
                disabled={disabled || !list?.length}
            >
                <span
                    className={`${styles.inner} ${
                        disabled ? styles.disabled : ''
                    }`}
                >
                    {selectedValue
                        ? listItemDisplay(selectedValue)
                        : placeholder}

                    <ArrowDropDownSharp className={styles.arrow} />
                </span>
            </button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                placement="bottom-start"
                transition
                disablePortal
                className={styles.menuList}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start'
                                    ? 'left top'
                                    : 'left bottom',
                        }}
                    >
                        <Paper className={styles.menuTable}>
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                >
                                    <MenuItem
                                        className={styles.header}
                                        disableRipple
                                    >
                                        {columnNames.map(name => (
                                            <span
                                                className={styles.listItemText}
                                                key={crypto.randomUUID()}
                                            >
                                                {name}
                                            </span>
                                        ))}
                                    </MenuItem>
                                    {list?.map(item => (
                                        <MenuItem
                                            onClick={e => handleClose(e, item)}
                                            key={crypto.randomUUID()}
                                            className={styles.listItem}
                                        >
                                            {listItemDisplay(item)}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};
