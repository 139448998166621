import { useContext } from 'react';

import { EditTolerancesContext } from './EditTolerancesContext';

export const useEditTolerances = () => {
    const context = useContext(EditTolerancesContext);

    if (context === null) {
        throw new Error(
            'useEditTolerances must be used within a EditTolerancesContext',
        );
    }

    return context;
};
