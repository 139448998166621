import { UseQueryResult } from '@tanstack/react-query';
import { FILE_STATUS } from 'constants/FileStatus';
import { PipeFunc } from 'hooks/usePipe/usePipe';
import { getApprovalStates } from 'pages/Collections/Submission/utils';
import { ProviderStatus } from 'types/provider';
import {
    SubmissionApprovalState,
    SubmissionStatus,
    SubmissionStep,
} from 'types/submission';
import { UserOrganisation } from 'types/user';

import { AwaitingParty } from './SubmissionSummary/utils';

export const queryStates = {
    UNINITIALISED: 1,
    LOADING: 2,
    LOADED: 3,
    LOADING_FAILED: 4,
};

const OFS_CODE = '5016';

export const getStateOfQueries = (queries: UseQueryResult[]) => {
    const every = (status: UseQueryResult['status']) =>
        queries.every(d => d.status === status);

    const some = (status: UseQueryResult['status']) =>
        queries.some(d => d.status === status);

    if (some('pending')) {
        return queryStates.LOADING;
    }

    if (every('success')) {
        return queryStates.LOADED;
    }

    if (some('error')) {
        return queryStates.LOADING_FAILED;
    }

    return queryStates.UNINITIALISED;
};

export const transformToSteps = (
    states: SubmissionStatus[],
): SubmissionStep[] => {
    const steps = states.reduce((acc, { step }) => {
        return { ...acc, [step.number]: { ...step, count: 0 } };
    }, {});

    return Object.values(steps);
};

export const addTotalSteps =
    (steps: SubmissionStep[]) => (providerStatus: ProviderStatus) => {
        return {
            ...providerStatus,
            totalSteps: steps.length,
        };
    };

export const transformToAwaitingParties = (states: SubmissionStatus[]) => {
    return states.reduce((output, state) => {
        return {
            ...output,
            [state.id]: state.awaitingParty,
        };
    }, {}) as AwaitingParty[];
};

export const addAwaitingParty =
    (awaitingParties: AwaitingParty[]) => (providerStatus: ProviderStatus) => {
        return {
            ...providerStatus,
            awaitingParty: providerStatus.latestSubmission
                ? awaitingParties[providerStatus.latestSubmission?.status.id]
                : { id: 1, name: 'Provider' },
        };
    };

export const getNextApprovalState = (
    approvalStateIndex: number,
    submissionApprovalStates: SubmissionApprovalState[],
) => {
    if (approvalStateIndex === submissionApprovalStates.length - 1) {
        return FILE_STATUS.APPROVED;
    } else if (approvalStateIndex !== -1) {
        return submissionApprovalStates.at(approvalStateIndex + 1);
    }
    return null;
};

export const addNextApprovalState = (providerStatus: ProviderStatus) => {
    if (!providerStatus?.latestSubmission) return providerStatus;

    const submissionApprovalStates =
        getApprovalStates(
            providerStatus.latestSubmission?.riskCode,
            providerStatus.provider?.countryCode,
        ) || [];

    const approvalStateIndex = submissionApprovalStates.findIndex(
        state => state.id === providerStatus?.latestSubmission?.status?.id,
    );

    const nextApprovalState =
        getNextApprovalState(approvalStateIndex, submissionApprovalStates) ||
        null;

    return {
        ...providerStatus,
        latestSubmission: {
            ...providerStatus?.latestSubmission,
            nextApprovalState,
        },
    };
};

export const addActionStates =
    (
        isHesa: boolean,
        activeOrganisation: UserOrganisation,
        isStatutoryCustomer: boolean,
    ) =>
    (providerStatus: ProviderStatus) => {
        const { latestSubmission, provider } = providerStatus;
        const { status } = latestSubmission ?? {};

        const readyForHesaApproval = Boolean(
            isHesa &&
                [
                    FILE_STATUS.HESA_APPROVAL.id,
                    FILE_STATUS.HESA_ANALYST_APPROVAL.id,
                ].includes(status?.id),
        );

        const readyForScApproval = Boolean(
            isStatutoryCustomer &&
                status?.id === FILE_STATUS.SC_APPROVAL.id &&
                (activeOrganisation?.id === provider?.regulatorCode ||
                    activeOrganisation?.id === OFS_CODE),
        );

        const canRequestResubmission = Boolean(
            isHesa &&
                [
                    FILE_STATUS.APPROVED.id,
                    FILE_STATUS.PROVIDER_SIGNOFF.id,
                    FILE_STATUS.SIGNED_OFF.id,
                ].includes(status?.id),
        );

        const readyForSignOff = Boolean(
            isHesa && FILE_STATUS.PROVIDER_SIGNOFF.id === status?.id,
        );

        const canCreateNilReturn = Boolean(
            isHesa && !providerStatus?.latestSubmission,
        );

        return {
            ...providerStatus,
            actionStates: {
                readyForHesaApproval,
                readyForScApproval,
                canRequestResubmission,
                readyForSignOff,
                canCreateNilReturn,
            },
        };
    };

export const pipe =
    <T>(...fns: PipeFunc<T>[]) =>
    (x: T) =>
        fns.reduce((v, f) => f(v), x);
